export function memoize<T, U>(fn: (arg: T) => U): (arg: T) => U {
  const cache = new Map<string, U>();

  return (arg: T) => {
    const cachedResult = cache.get(JSON.stringify(arg));
    if (cachedResult !== undefined) {
      return cachedResult;
    }

    const result = fn(arg);
    cache.set(JSON.stringify(arg), result);
    return result;
  };
}
