import { AppClientData } from "../types/AppClient";
import { ClientCredentialsDetails } from "../types/ClientCredentialsDetails";
import { http } from "./http";
const baseUrl = "/api/ve-secure-settings";

export const getAppClientById = async (currentClientId: string) => {
  const { data } = await http.get(
    `${baseUrl}/client-settings/${currentClientId}`
  );
  return data;
};

export const updateAppClientData = async (payload: AppClientData) => {
  const { data } = await http.post(`${baseUrl}/client-settings`, payload);
  return data;
};

export const getClientCredentials = async () => {
  const { data } = await http.get(`${baseUrl}/client-credentials`);
  return data;
};

export const setClientCredentials = async (
  payload: ClientCredentialsDetails
) => {
  return await http.post(`${baseUrl}/client-credentials`, payload);
};
