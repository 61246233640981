import { Checkbox } from "@c11/components";
import React from "react";
import { Page } from "../../../../types";
import { toggleElemenetInArray } from "../../../../utils/toggleItemInArray";
import styles from "../../styles.module.css";
import Tooltip from "../../../../components/tooltip/Tooltip";

export const AllowedOAuthScopes: view = ({
  allowedOauthScopes = observe.pages[Page.APP_CLIENT].formData
    .allowedOauthScopes,
  updateallowedOauthScopes = update.pages[Page.APP_CLIENT].formData
    .allowedOauthScopes,
  availableScopes = observe.pages[Page.APP_CLIENT].availableAuthScopes,
  t = observe.lang.t,
}) => {
  return (
    <>
      <label className={styles.label}>Allowed OAuth Scopes </label>
      <div className={styles.idProvidersList}>
        {availableScopes.map((scope: string) => (
          <div key={scope} className={styles.idProviderListItem}>
            <Tooltip message={t(`oauth2 scopes ${scope} desc`)} showIcon>
              <Checkbox
                isChecked={allowedOauthScopes.includes(scope)}
                isDisabled={scope === "openid"}
                onClick={() => {
                  updateallowedOauthScopes.set(
                    toggleElemenetInArray(allowedOauthScopes, scope)
                  );
                }}
              >
                {scope}
              </Checkbox>
            </Tooltip>
          </div>
        ))}
      </div>
    </>
  );
};
