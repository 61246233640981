import { AsyncRequestState } from "../../../types";
import { http } from "../../../services/http";
import { setClientCredentials } from "../../../services/api";

export const createAppClient: producer = async ({
  createAppClientTrigger = observe.organization.triggers.createAppClient,
  updateAppClientTrigger = update.organization.triggers.createAppClient,
  appClientId = update.clients.currentClientId,
  appClientSecret = update.clients.currentAppClientSecret,
  updateSubmitNewAppClientRequest = update.clients.requests.submitNewAppClient,
  name = get.clients.appClientName.value(),
  items = get.organization.items.value(),
  updateOrganizationItems = update.organization.items,
}) => {
  if (!createAppClientTrigger) {
    return;
  }
  updateAppClientTrigger.remove();
  updateSubmitNewAppClientRequest.set(AsyncRequestState.PENDING);
  try {
    const response = await setClientCredentials({ name });

    appClientId.set(response.data.clientID);
    appClientSecret.set(response.data.clientSecret);
    const currentItems = items || [];
    currentItems.push({ clientID: response.data.clientID, name });
    updateOrganizationItems.set(currentItems);
    updateSubmitNewAppClientRequest.set(AsyncRequestState.FULFILLED);
  } catch (error) {
    updateSubmitNewAppClientRequest.set(AsyncRequestState.FAILED);
  }
};
