import { convertOidcStateDataToDTO } from "../../../utils/dto";
import { http } from "../../../services/http";
import { AsyncRequestState, Page } from "../../../types";
import { immediateToast } from "izitoast-react";
import { updateAppClientData } from "../../../services/api";

export const resetRequestStateWhenDoingChangesInForm: producer = ({
  formData = observe.pages[Page.APP_CLIENT].formData,
  updateSubmitAppClientRequest = update.pages[Page.APP_CLIENT].requests
    .submitAppClient,
}) => {
  updateSubmitAppClientRequest.set(AsyncRequestState.UNINITIALISED);
};

export const submitAppClientData: producer = async ({
  trigger = observe.pages[Page.APP_CLIENT].triggers.submitAppClientData,
  updateAppClientTrigger = update.pages[Page.APP_CLIENT].triggers
    .submitAppClientData,
  formData = get.pages[Page.APP_CLIENT].formData.value(),
  updateSubmitAppClientRequest = update.pages[Page.APP_CLIENT].requests
    .submitAppClient,
  updateAppClientPageFormData = update.pages[Page.APP_CLIENT].formData,
  currentClient = get.clients.currentClient.value(),
  updateCurrentClient = update.clients.currentClient,
}) => {
  if (!trigger) {
    return;
  }

  updateAppClientTrigger.remove();
  updateSubmitAppClientRequest.set(AsyncRequestState.PENDING);

  try {
    const data = await updateAppClientData({
      oidc: convertOidcStateDataToDTO(formData),
      clientID: currentClient.clientID,
    });

    updateAppClientPageFormData.set(data.oidc);
    updateCurrentClient.set({ ...currentClient, oidc: formData });
    updateSubmitAppClientRequest.set(AsyncRequestState.FULFILLED);
    immediateToast("success", {
      message: "Settings were submitted successfully!",
      timeout: 10000,
      closeOnClick: true,
      position: "topRight",
    });
  } catch (error) {
    updateSubmitAppClientRequest.set(AsyncRequestState.FAILED);
    immediateToast("error", {
      message: `Request failed!`,
      timeout: 10000,
      closeOnClick: true,
      position: "topRight",
    });
  }
};
