import { isEqual } from "lodash";
import { Page } from "../../../types";

export const initializeCustomUISettingsPageState: producer = async ({
  updateAppClientCustomUIData = update.pages[Page.APP_CLIENT].customUIData,
  updateFormInitialization = update.pages[Page.APP_CLIENT]
    .isCustomUIFormInitialized,
  isFormInitialized = get.pages[
    Page.APP_CLIENT
  ].isCustomUIFormInitialized.value(),
  currentClient = observe.clients.currentClient,
}) => {
  if (!currentClient || isFormInitialized) {
    return;
  }
  updateAppClientCustomUIData.set(currentClient.ui);
  updateFormInitialization.set(true);
  return  () => {
    updateFormInitialization.remove();
  }
};

export const detectUISettingsChanges: producer = ({
  customUIData = observe.pages[Page.APP_CLIENT].customUIData,
  currentClient = get.clients.currentClient.ui.value(),
  updateAreChangesMade = update.pages[Page.APP_CLIENT].areUISettingsChangesMade,
  isFormInitialized = get.pages[
    Page.APP_CLIENT
  ].isCustomUIFormInitialized.value(),
}) => {
  if (isFormInitialized) {
    updateAreChangesMade.set(!isEqual(customUIData, currentClient));
  }
};

export const resetUISettingsData: producer = ({
  updateEntireFormData = update.pages[Page.APP_CLIENT].customUIData,
  currentClient = get.clients.currentClient.value(),
  trigger = observe.pages[Page.APP_CLIENT].triggers.resetUISettingsData,
  triggerResetForm = update.pages[Page.APP_CLIENT].triggers.resetUISettingsData,
}) => {
  if (!trigger) {
    return;
  }
  triggerResetForm.remove();
  updateEntireFormData.set(currentClient.ui);
};
