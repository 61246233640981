import React from "react";

export const MobilePhone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="79"
      viewBox="0 0 49 79"
      fill="none"
    >
      <rect
        x="1.60645"
        y="1.73218"
        width="46"
        height="75.8378"
        rx="7"
        stroke="#999999"
        strokeWidth="2"
      />
      <path
        d="M18.6391 41.5592H30.5739C31.0115 41.5592 31.3695 41.1904 31.3695 40.7395L31.3698 30.9024C31.3698 30.4515 31.0118 30.0827 30.5742 30.0827H28.983L28.9827 28.0334C28.9827 25.5493 27.0175 23.5245 24.6065 23.5245C22.1958 23.5245 20.2305 25.5493 20.2305 28.0334V30.0827H18.6393C18.2017 30.0827 17.8438 30.4516 17.8438 30.9024V40.7395C17.8435 41.1904 18.2015 41.5592 18.6391 41.5592ZM21.8217 28.0332C21.8217 26.451 23.0711 25.1638 24.6065 25.1638C26.1422 25.1638 27.3915 26.451 27.3915 28.0332V30.0826H21.822L21.8217 28.0332ZM19.4349 31.722H29.7785V39.9195H19.4349V31.722Z"
        fill="#444444"
      />
      <path
        d="M19.29 6.56439C16.5848 6.56439 14.3813 4.41593 14.2928 1.73218H34.9196C34.8311 4.41592 32.6276 6.56439 29.9223 6.56439H19.29Z"
        fill="#E1E1E1"
        stroke="#999999"
        strokeWidth="2"
      />
      <path
        d="M13.7924 47.3013V50.516L16.935 49.4204L17.5116 51.0205L14.369 52.0441L16.3151 54.7542L14.888 55.7778L12.9995 52.9667L11.0966 55.7778L9.6262 54.7542L11.6012 52.0441L8.42969 51.0205L9.00632 49.4204L12.1201 50.5448V47.3013H13.7924Z"
        fill="#444444"
      />
      <path
        d="M25.4281 47.3013V50.516L28.5708 49.4204L29.1474 51.0205L26.0047 52.0441L27.9509 54.7542L26.5237 55.7778L24.6352 52.9667L22.7324 55.7778L21.2619 54.7542L23.2369 52.0441L20.0654 51.0205L20.6421 49.4204L23.7559 50.5448V47.3013H25.4281Z"
        fill="#444444"
      />
      <path
        d="M37.0639 47.3013V50.516L40.2065 49.4204L40.7831 51.0205L37.6405 52.0441L39.5866 54.7542L38.1595 55.7778L36.271 52.9667L34.3681 55.7778L32.8977 54.7542L34.8727 52.0441L31.7012 51.0205L32.2778 49.4204L35.3916 50.5448V47.3013H37.0639Z"
        fill="#444444"
      />
    </svg>
  );
};
