/* 
    We try to decode the file using the Buffer.from() method and check if it throws any errors. 
    This function also treats strings that start with "data:image/"
*/
import { Buffer } from "buffer";
export const isBinaryBase64 = (base64String: string): boolean => {
  const base64Regex =
    /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{4}|[A-Za-z0-9+\/]{3}=|[A-Za-z0-9+\/]{2}={2})$/;

  let base64Data = base64String;

  if (base64String.startsWith("data:image/")) {
    base64Data = base64String.split(",")[1];
  }

  if (base64Regex.test(base64Data)) {
    try {
      Buffer.from(base64Data, "base64");
      return true;
    } catch (error) {
      console.log("error", error);
    }
  }

  return false;
};
