import bg_BG from "ve-ui-theme/src/assets/flags/bg_BG.png";
import cs_CZ from "ve-ui-theme/src/assets/flags/cs_CZ.png";
import da_DK from "ve-ui-theme/src/assets/flags/da_DK.png";
import de_AT from "ve-ui-theme/src/assets/flags/de_AT.png";
import de_DE from "ve-ui-theme/src/assets/flags/de_DE.png";
import de_LI from "ve-ui-theme/src/assets/flags/de_LI.png";
import el_CY from "ve-ui-theme/src/assets/flags/el_CY.png";
import el_GR from "ve-ui-theme/src/assets/flags/el_GR.png";
import en_EN from "ve-ui-theme/src/assets/flags/en_EN.png";
import en_GB from "ve-ui-theme/src/assets/flags/en_GB.png";
import en_IE from "ve-ui-theme/src/assets/flags/en_IE.png";
import en_US from "ve-ui-theme/src/assets/flags/en_US.png";
import es_ES from "ve-ui-theme/src/assets/flags/es_ES.png";
import et_EE from "ve-ui-theme/src/assets/flags/et_EE.png";
import fi_FI from "ve-ui-theme/src/assets/flags/fi_FI.png";
import fr_CH from "ve-ui-theme/src/assets/flags/fr_CH.png";
import fr_FR from "ve-ui-theme/src/assets/flags/fr_FR.png";
import fr_LU from "ve-ui-theme/src/assets/flags/fr_LU.png";
import hr_HR from "ve-ui-theme/src/assets/flags/hr_HR.png";
import hu_HU from "ve-ui-theme/src/assets/flags/hu_HU.png";
import is_IS from "ve-ui-theme/src/assets/flags/is_IS.png";
import it_IT from "ve-ui-theme/src/assets/flags/it_IT.png";
import lt_LT from "ve-ui-theme/src/assets/flags/lt_LT.png";
import lv_LV from "ve-ui-theme/src/assets/flags/lv_LV.png";
import mk_MK from "ve-ui-theme/src/assets/flags/mk_MK.png";
import mt_MT from "ve-ui-theme/src/assets/flags/mt_MT.png";
import nb_NO from "ve-ui-theme/src/assets/flags/nb_NO.png";
import nl_BE from "ve-ui-theme/src/assets/flags/nl_BE.png";
import nl_NL from "ve-ui-theme/src/assets/flags/nl_NL.png";
import pl_PL from "ve-ui-theme/src/assets/flags/pl_PL.png";
import pt_PT from "ve-ui-theme/src/assets/flags/pt_PT.png";
import ro_RO from "ve-ui-theme/src/assets/flags/ro_RO.png";
import sk_SK from "ve-ui-theme/src/assets/flags/sk_SK.png";
import sl_SI from "ve-ui-theme/src/assets/flags/sl_SI.png";
import sv_SE from "ve-ui-theme/src/assets/flags/sv_SE.png";
import zh_CN from "ve-ui-theme/src/assets/flags/zh_CN.png";
import zh_HK from "ve-ui-theme/src/assets/flags/zh_HK.png";
import zh_SG from "ve-ui-theme/src/assets/flags/zh_SG.png";

const flags = { bg_BG, cs_CZ, da_DK, de_AT, de_DE, de_LI, el_CY, el_GR, en_EN, en_GB, en_IE, en_US, es_ES, et_EE, fi_FI, fr_CH, fr_FR, fr_LU, hr_HR, hu_HU, is_IS, it_IT, lt_LT, lv_LV, mk_MK, mt_MT, nb_NO, nl_BE, nl_NL, pl_PL, pt_PT, ro_RO, sk_SK, sl_SI, sv_SE, zh_CN, zh_HK, zh_SG };

export default flags;