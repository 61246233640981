import axios from "axios";

const httpInstance = axios.create({});

httpInstance.interceptors.request.use((config) => {
  const identity = window.identityService;
  const companyId = window.companyService.getInstance()?.currentCompanyId || "";

  if (typeof config.headers["Authorization"] === "undefined") {
    config.headers["Authorization"] = `${identity.getInstance()._authData}`;
  }
  config.headers!["x-namespace"] = `/companies/${companyId}`;

  return config;
});

export const http = httpInstance;
