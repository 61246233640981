import { Page, PageStates } from "../../../types";
import { http } from "../../../services/http";
import { getClientCredentials } from "../../../services/api";

type Props = {
  fetchOrganizationTrigger: State["organization"]["triggers"]["fetchOrganizations"];
  updateOrgTrigger: Update<
    State["organization"]["triggers"]["fetchOrganizations"]
  >;
  updateOrgItems: Update<State["organization"]["items"]>;
  updateDashboardPageState: Update<State["pages"][Page.DASHBOARD]["pageState"]>;
};

export const checkPageState: producer = ({
  pageState = observe.pages[Page.DASHBOARD].pageState,
  updateOrgTrigger = update.organization.triggers.fetchOrganizations,
}) => {
  if (pageState) {
    return;
  }
  updateOrgTrigger.set(Date.now());
};

export const fetchAppClientIds: producer = async ({
  fetchOrganizationTrigger = observe.organization.triggers.fetchOrganizations,
  updateOrgTrigger = update.organization.triggers.fetchOrganizations,
  updateOrgItems = update.organization.items,
  updateDashboardPageState = update.pages[Page.DASHBOARD].pageState,
}: Props) => {
  if (!fetchOrganizationTrigger) {
    return;
  }
  updateDashboardPageState.set(PageStates.LOADING);
  updateOrgTrigger.remove();
  try {
    const clientCredentials = await getClientCredentials();
    updateDashboardPageState.set(PageStates.INITIALISED);
    updateOrgItems.set(clientCredentials);
  } catch (error) {
    updateDashboardPageState.set(PageStates.FAILED);
  }
};

export const refreshCurrentOrganization: producer = ({
  updateCurrentOrg = update.organization.currentOrganization,
}) => {
  updateCurrentOrg.remove();
};
