import { Button } from "@c11/components";
import React from "react";
import { DeleteIconFilled } from "../../../../../components/icons";
import { Page } from "../../../../../types";
import styles from "../../../styles.module.css";
import { CallbackUrlItem } from "./CallbackUrlItem";
import * as Producers from "./producers";
import Tooltip from "../../../../../components/tooltip/Tooltip";

export const CallbackUrlsList: view = ({
  ids = observe.pages[Page.APP_CLIENT].formData.callbackUrls.ids,
  canNewItemBeAdded = observe.pages[Page.APP_CLIENT].formData.callbackUrls
    .canNewItemBeAdded,
  updateRemoveCallbackTrigger = update.pages[Page.APP_CLIENT].formData
    .callbackUrls.removeItem,
  addNewItemTrigger = update.pages[Page.APP_CLIENT].formData.callbackUrls
    .addItem,
  t = observe.lang.t,
}) => {
  return (
    <div>
      <h2 className="grey"> Sign in and sign out URLs</h2>
      <p>{t("signin_signout_urls info")}</p>
      <div>
        <Tooltip message={t("signin_signout_urls callbackurl desc")} showIcon>
          <h4 className={styles.callbackUrlsHeading}>
            {ids.length > 1 ? "Callback URLs" : "Callback URL"}:
          </h4>
        </Tooltip>

        <div>
          {ids.map((id: string, index: number) => (
            <div key={id} className={styles.callbackItemContainer}>
              <CallbackUrlItem id={id} />

              {!!index && (
                <DeleteIconFilled
                  onClick={() => updateRemoveCallbackTrigger.set(id)}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <Button
        isDisabled={!canNewItemBeAdded}
        onClick={() => addNewItemTrigger.set(Date.now())}
      >
        +
      </Button>
    </div>
  );
};

CallbackUrlsList.producers(Producers);
