export const EditIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_56_282)">
      <path
        d="M0 9.65C0 9.5 0.05 9.35 0.15 9.25L7.45 2L9.25 0.15C9.5 -0.05 9.85 -0.05 10.05 0.15L12.65 2.8C12.85 3.05 12.85 3.4 12.65 3.6L3.6 12.7C3.5 12.8 3.35 12.85 3.2 12.85H0.55C0.4 12.85 0.25 12.8 0.15 12.7C0.05 12.6 0 12.45 0 12.3V9.65ZM1.15 9.9V11.75H3L9.7 5.05L7.85 3.2L1.15 9.9ZM9.7 1.35L8.65 2.4L10.5 4.25L11.5 3.2L9.7 1.35Z"
        fill="#218BCB"
      />
      <path
        d="M0.1 14.75H15.85C15.9 14.75 15.95 14.8 15.95 14.85V15.85C15.95 15.9 15.9 15.95 15.85 15.95H0.1C0.05 16 0 15.95 0 15.9V14.9C0 14.8 0.05 14.75 0.1 14.75Z"
        fill="#218BCB"
      />
    </g>
    <defs>
      <clipPath id="clip0_56_282">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
