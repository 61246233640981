import React from "react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import styles from "./styles.module.css";

const TooltipInfoIcon = () => {
  return (
    <div className={styles.toolTipContainer}>
      <InfoOutlineIcon />
    </div>
  );
};

export default TooltipInfoIcon;
