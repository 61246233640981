/* 
  Checks whether the size of a file is bigger than maximum allowed size, in Kilo Bytes.
  Base64 encoding increases the size of the data by approximately 33%,
  meaning that a 300KB base64-encoded string would represent approximately 225.7KB of actual data.
  To be on the safe side and allow for any potential overhead in the encoding process,
  it would be advisable to keep the size of the original PNG file below 227.3KB in that case.
*/
export const isFileSizeLessThanMax = (
  file: File,
  maxKBSize: number
): boolean => {
  const fileSizeInBytes = file.size;
  const fileSizeInKB = fileSizeInBytes / 1024;
  return fileSizeInKB < maxKBSize;
};
