import { Button, Modal } from "@c11/components";
import styles from "./styles.module.css";

interface ConfirmDeleteModalProps {
  isLoading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  appName: string;
  isCompleted: boolean;
}

export const ConfirmDeleteModal = ({
  isLoading,
  appName,
  onConfirm,
  onCancel,
  isCompleted,
}: ConfirmDeleteModalProps) => {
  return (
    <Modal>
      <div className={styles.modalContainer}>
        <div className={styles.content}>
          {!isCompleted ? (
            <>
              <h2>Confirm Delete</h2>
              <div>
                Are you sure you want to delete the app client "{appName}"?
              </div>
            </>
          ) : (
            <>
              <h2>Deleted!</h2>
              <div>Deleted app client "{appName}"</div>
            </>
          )}

          <div className={styles.buttonContainer}>
            <Button variant="secondary" onClick={onCancel}>
              {isCompleted ? "Close" : "Cancel"}
            </Button>
            {!isCompleted && (
              <Button
                isLoading={isLoading}
                variant="danger"
                onClick={onConfirm}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
