export const DeleteIconFilled = ({ onClick }: { onClick?: () => void }) => (
  <svg
    onClick={onClick}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.1104C12.4183 16.1104 16 12.5286 16 8.11035C16 3.69207 12.4183 0.110352 8 0.110352C3.58172 0.110352 0 3.69207 0 8.11035C0 12.5286 3.58172 16.1104 8 16.1104ZM4.66176 5.55318C4.44608 5.33749 4.44608 4.9878 4.66176 4.77211C4.87745 4.55643 5.22714 4.55643 5.44282 4.77211L8 7.32929L10.5572 4.77211C10.7729 4.55643 11.1226 4.55643 11.3382 4.77211C11.5539 4.9878 11.5539 5.33749 11.3382 5.55318L8.78106 8.11035L11.3382 10.6675C11.5539 10.8832 11.5539 11.2329 11.3382 11.4486C11.1226 11.6643 10.7729 11.6643 10.5572 11.4486L8 8.89141L5.44282 11.4486C5.22714 11.6643 4.87744 11.6643 4.66176 11.4486C4.44608 11.2329 4.44608 10.8832 4.66176 10.6675L7.21894 8.11035L4.66176 5.55318Z"
      fill="#218BCB"
    />
  </svg>
);
