import React from "react";
import Select from "react-select";
import { Page } from "../../../../../types";
import * as Producers from "./producers";
import flags from "../../../../../flags";

export const LanguageSelection: view = ({
  mappedAvailableLanguages = observe.pages[Page.APP_CLIENT]
    .mappedAvailableLanguages,
  updateChosenLanguage = update.pages[Page.APP_CLIENT].formData.language,
  mappedChosenLanguage = observe.pages[Page.APP_CLIENT].formData
    .mappedChosenLanguage,
  t = observe.lang.t,
}) => {
  const handleChange = (selectedOption: any) => {
    updateChosenLanguage.set(selectedOption.value);
  };

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={flags[value]}
        alt={`${value.split("_")[1].toLowerCase()} flag`}
        style={{ marginRight: "10px" }}
      />
      <span>{label}</span>
    </div>
  );

  return (
    <div>
      <h2 className="grey">Default language</h2>
      <Select
        value={mappedChosenLanguage}
        options={mappedAvailableLanguages}
        onChange={handleChange}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

LanguageSelection.producers(Producers);
