import { Page, PageStates } from "../../../types";
import styles from "../styles.module.css";
import { IdentityProviders } from "./formParts/IdentityProviders";
import { CallbackUrlsList } from "./formParts/CallbackUrls/CallbackUrlsList";
import { AllowedOAuthFlows } from "./formParts/OAuthFlows";
import { AllowedOAuthScopes } from "./formParts/OAuthScopes";
import { FormCTAs } from "./FormCTAs";
import { FormFeedback } from "./FormFeedback";
import { Loader } from "../../../components/Loader/Loader";
import { LanguageSelection } from "./formParts/Language/LanguageSelection";
import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import Tooltip from "../../../components/tooltip/Tooltip";

interface EngineProps {
  isFormInitialized: boolean;
  appClientName: string;
  appClientPageState: PageStates;
}

export const AppClientSettingsForm: view = ({
  appClientName = observe.pages[Page.APP_CLIENT].formData.name,
  appClientPageState = observe.pages[Page.APP_CLIENT].settingsPageSate,
  t = observe.lang.t,
}: EngineProps) => {
  if (!appClientPageState || appClientPageState === PageStates.LOADING) {
    return <Loader />;
  }

  if (appClientPageState === PageStates.FAILED) {
    return <h2>The setting could not be loaded. Try again</h2>;
  }
  return (
    <form className={styles.clientManagementFormContainer}>
      <IdentityProviders />
      <CallbackUrlsList />
      <LanguageSelection />
      <div className={styles.oauth}>
        <Tooltip message={t("oauth2 desc")} showIcon>
          <h2 className=" grey">OAuth 2.0</h2>
        </Tooltip>
        <AllowedOAuthFlows />
        <AllowedOAuthScopes />
      </div>
      <FormCTAs />
    </form>
  );
};
