import { guid } from "../../../../../../utils/randomIdGenerator";
import { Page } from "../../../../../../types";

export const checkIfNewItemCanBeAdded: producer = ({
  callbackUrls = observe.pages[Page.APP_CLIENT].formData.callbackUrls,
  updateCanNewItemBeAdded = update.pages[Page.APP_CLIENT].formData.callbackUrls
    .canNewItemBeAdded,
}) => {
  const { ids, items } = callbackUrls;
  if (!ids || !items) {
    return;
  }
  if (items[ids[ids.length - 1]]) {
    updateCanNewItemBeAdded.set(true);
  } else {
    updateCanNewItemBeAdded.set(false);
  }
};

export const addNewItem: producer = ({
  updateAddNewItemTrigger = update.pages[Page.APP_CLIENT].formData.callbackUrls
    .addItem,
  addNewItemTrigger = observe.pages[Page.APP_CLIENT].formData.callbackUrls
    .addItem,
  ids = get.pages[Page.APP_CLIENT].formData.callbackUrls.ids.value(),
  items = get.pages[Page.APP_CLIENT].formData.callbackUrls.items.value(),
  updateCallbackUrls = update.pages[Page.APP_CLIENT].formData.callbackUrls,
}) => {
  if (!addNewItemTrigger) {
    return;
  }
  updateAddNewItemTrigger.remove();

  const idsCopy = [...ids];
  const newItemId = guid();
  idsCopy.push(newItemId);

  updateCallbackUrls.set({
    ids: idsCopy,
    items: { ...items, newItemId: "" },
  });
};

export const DeleteItem: producer = ({
  updateRemoveCallbackTrigger = update.pages[Page.APP_CLIENT].formData
    .callbackUrls.removeItem,
  removeItemTrigger = observe.pages[Page.APP_CLIENT].formData.callbackUrls
    .removeItem,
  ids = get.pages[Page.APP_CLIENT].formData.callbackUrls.ids.value(),
  items = get.pages[Page.APP_CLIENT].formData.callbackUrls.items.value(),
  updateCallbackUrls = update.pages[Page.APP_CLIENT].formData.callbackUrls,
}) => {
  if (!removeItemTrigger) {
    return;
  }
  updateRemoveCallbackTrigger.remove();

  const idsCopy = [...ids];
  idsCopy.splice(idsCopy.indexOf(removeItemTrigger), 1);

  const itemsCopy = { ...items };
  delete itemsCopy[removeItemTrigger];

  updateCallbackUrls.set({
    ids: idsCopy,
    items: itemsCopy,
  });
};
