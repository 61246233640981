export const syncLanguage: producer = ({
  translation = observe.services.translation,
  langInstance = observe.lang.instance,
  eventBusFn = observe.services.eventBus,
}) => {
  if (!translation || !langInstance || !eventBusFn) {
    return;
  }

  const inst = translation.getInstance();
  const eventBus = eventBusFn();

  if (!inst) {
    console.error("Could not get instance for language service");
    return;
  }

  const updateLanguage = () => {
    let lang = inst.getLanguage();
    if (lang === "en_EN") {
      lang = "en_US";
    }
    console.log("gotHere1", lang);
    langInstance.changeLanguage(lang);
  };

  eventBus.on("translation.changed", updateLanguage);

  return () => {
    eventBus.off("translation.changed", updateLanguage);
  };
};
