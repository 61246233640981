import {
  createModule,
  loadStylesheets,
  removeAssets,
} from "@c11/components.utils";
import { createApp } from "./createApp";
import "./styles.css";

const PATH = "ve-secured-client-management";
export const { unmount, mount, bootstrap, update } = createModule(
  "client-management",
  createApp,
  {
    bootstrap: (config: any) =>
      new Promise((resolve, reject) => {
        loadStylesheets(config.name, [
          `https://dev-verified.eu/ve-secured-client-management/main.css`,
        ]);
        resolve(true);
      }),
    unmount: (config: any) => removeAssets(config.name),
  }
);
