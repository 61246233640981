import { Checkbox } from "@c11/components";
import React from "react";
import { OAuthFlows, Page } from "../../../../types";
import { toggleElemenetInArray } from "../../../../utils/toggleItemInArray";
import StylesModuleCssModule from "../../styles.module.css";
import Tooltip from "../../../../components/tooltip/Tooltip";
import { MessageConstants } from "../MessageConstants";

export const AllowedOAuthFlows: view = ({
  allowedOauthFlows = observe.pages[Page.APP_CLIENT].formData.allowedOauthFlows,
  updateAllowedOauthFlows = update.pages[Page.APP_CLIENT].formData
    .allowedOauthFlows,
  availableFlows = observe.pages[Page.APP_CLIENT].availableAuthFlows,
  t = observe.lang.t,
}) => {
  const flowDescriptions: any = {
    authorization_code: MessageConstants.AuthorizationCodeDesc,
    implicit: MessageConstants.ImplicitDesc,
    hybrid: MessageConstants.HybridDesc,
  };
  return (
    <>
      <p className={StylesModuleCssModule.infoText}>{t("oauth2 info")}</p>
      <label className={StylesModuleCssModule.label}>Allowed OAuth Flows</label>
      <div className={StylesModuleCssModule.idProvidersList}>
        {availableFlows.map((flow: string) => (
          <div key={flow} className={StylesModuleCssModule.idProviderListItem}>
            <Tooltip message={t(`oauth2 flows ${flow} desc`)} showIcon>
              <Checkbox
                isChecked={allowedOauthFlows.includes(flow)}
                onClick={() => {
                  updateAllowedOauthFlows.set(
                    toggleElemenetInArray(allowedOauthFlows, flow)
                  );
                }}
              >
                {flow}
              </Checkbox>
            </Tooltip>
          </div>
        ))}
      </div>
    </>
  );
};
