export const BuildingIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M10.8446 17H0.998505V5.30768L5.92157 1L10.8446 5.30768V17Z"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8462 17.0001H17V8.38477H10.8462"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.07468 10.8457H7.76697"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.07468 7.1543H7.76697"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
