import { memoize } from "./memoize";
import { guid } from "./randomIdGenerator";

export const convertOidcStateDataToDTO = memoize((stateData: any) => {
  const stateCopy = { ...stateData };

  const { callbackUrls } = stateCopy;
  stateCopy.callbackUrls = Object.values(callbackUrls.items).filter(
    (value) => !!value
  );

  return stateCopy;
});

export const convertOidcDTOToStateData = memoize((dto: any) => {
  let { callbackUrls } = dto;
  if (callbackUrls.length === 0) {
    callbackUrls = [""];
  }

  const callbackUrlsIds = Array.from({ length: callbackUrls.length }, () =>
    guid()
  );

  const callbackUrlsItems = {};

  callbackUrls.forEach((url, index) => {
    callbackUrlsItems[callbackUrlsIds[index]] = url;
  });

  return {
    ...dto,
    callbackUrls: {
      ids: callbackUrlsIds,
      items: callbackUrlsItems,
    },
  };
});
