import { TextField } from "@c11/components";
import styles from "../../../styles.module.css";
import React from "react";
import { Page } from "../../../../../types";

export const CallbackUrlItem: view = ({
  id,
  url = observe.pages[Page.APP_CLIENT].formData.callbackUrls.items[prop.id],
  updateUrl = update.pages[Page.APP_CLIENT].formData.callbackUrls.items[
    prop.id
  ],
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateUrl.set(e.target.value);
  return (
    <div className={styles.callbackInputContainer}>
      <TextField value={url} onChange={handleChange} />
    </div>
  );
};
