export * from "./requests";
export * from "./constants";
export * from "./pages";
export * from "./veSecuredMethods";
export * from "./OAuthFlows";
export * from "./OAuthScopes";

export interface EngineUpdateType {
  merge: Function;
  pop: Function;
  push: Function;
  remove: Function;
  set: Function;
}

declare global {
  interface Window {
    identityService: any;
    companyService: any;
  }
}
