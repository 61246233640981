import React from "react";
import { Container } from "../../components/container/Container";
import { AppClientSettingsForm } from "./AppClientSettingsForm/AppClientSettingsForm";
import * as Producers from "./producers";
import {
  EngineUpdateType,
  Page,
  PageStates,
  TypeOfSettings,
} from "../../types";
import { ToggleSettingsType } from "../../components/toggleSettingsType/ToggleSettingsType";
import { OidcUISettings } from "./oidcUISettings/OidcUISettings";
import { Button } from "@c11/components";
import { OIDCClientSettingsStorageItem } from "../../core/domain/AppClient/AppClient";
import styles from "./styles.module.css";
import { Loader } from "../../components/Loader/Loader";
import { Divider } from "../../components/divider/Divider";

interface EngineProps {
  currentClient: OIDCClientSettingsStorageItem;
  updateGetCurrentClientSettings: EngineUpdateType;
  settingsVariant: string;
  currentClientId: string;
  settingsVariantTrigger: EngineUpdateType;
  updateCurrentClientId: EngineUpdateType;
}

export const AppClientManagement: view = ({
  appClientPageState = observe.pages[Page.APP_CLIENT].state,
  updateCurrentClientId = update.clients.currentClientId,
  updateGetCurrentClientSettings = update.clients.triggers.getCurrentClient,
  settingsVariant = observe.pages[Page.APP_CLIENT].settingsVariant,
  updateCurrentPage = update.pages.currentPage,
  appClientName = observe.pages[Page.APP_CLIENT].formData.name,
}: EngineProps) => {
  if (!appClientPageState) {
    updateGetCurrentClientSettings.set(Date.now());

    return (
      <div className={styles.appClientLoadingContainer}>
        <h1>Fetching current organization... </h1>
        <Loader />
      </div>
    );
  }

  if (appClientPageState === PageStates.FAILED) {
    return (
      <p>
        We could not fetch the data. Please try again by refreshing the page
      </p>
    );
  }

  const returnToMainPage = () => {
    updateCurrentPage.remove();
    updateCurrentClientId.remove();
  };

  return (
    <Container>
      <div style={{ background: "white", padding: "10px" }}>
        <div className={styles.navBar}>
          <div className={styles.toggle}>
            <ToggleSettingsType />
          </div>
          <div className={styles.button}>
            <Button onClick={returnToMainPage} variant="secondary">
              Close
            </Button>
          </div>
        </div>
        <Divider size="lg" />
        <h2 className={styles.title}>{appClientName as string}</h2>
        {settingsVariant === TypeOfSettings.OIDC_UI_SETTINGS && (
          <OidcUISettings />
        )}
        {settingsVariant === TypeOfSettings.APP_CLIENT_SETTINGS && (
          <AppClientSettingsForm />
        )}
      </div>
    </Container>
  );
};

AppClientManagement.producers(Producers);
