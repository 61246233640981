import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const translation: producer = ({
  updateInstance = update.lang.instance,
  instance = get.lang.instance,
  updateT = update.lang.t,
  path = observe.path,
}) => {
  if (instance.value()) {
    return;
  }
  console.log(path, "THEP ATH");
  const localeFile = "{{lng}}/{{ns}}.json";
  const pathPrefix =
    window.location.hostname === "localhost"
      ? "/"
      : `./ve-secured-client-management/`;
  const localesPath = `${pathPrefix}locales`;

  const i18nextOptions = {
    fallbackLng: "en_US",
    ns: ["translation"],
    defaultNS: "translation",
    debug: false,
    backend: {
      loadPath: `${localesPath}/${localeFile}`,
    },
  };

  const setLang = () => {
    updateT.set((...args) => i18n.t.call(i18n, args));
  };

  i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(i18nextOptions)
    .then((t) => {
      setLang();
    })
    .catch((e) => {
      console.error(e);
    });

  i18n.on("languageChanged", (t) => {
    setLang();
  });

  updateInstance.set(i18n);
};
