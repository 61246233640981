import { isBinaryBase64 } from "../../../services/isBinary";
import { http } from "../../../services/http";
import { AsyncRequestState, Page } from "../../../types";
import { updateAppClientData } from "../../../services/api";

export const resetRequestStateWhenDoingChangesInUISettings: producer = ({
  customUIData = observe.pages[Page.APP_CLIENT].customUIData,
  updateSubmitUISettingsRequest = update.pages[Page.APP_CLIENT].requests
    .submitCustomUIData,
}) => {
  updateSubmitUISettingsRequest.set(AsyncRequestState.UNINITIALISED);
};

export const submitUISettingsData: producer = async ({
  trigger = observe.pages[Page.APP_CLIENT].triggers.submitUISettingsData,
  updateAppClientTrigger = update.pages[Page.APP_CLIENT].triggers
    .submitUISettingsData,
  customUIData = get.pages[Page.APP_CLIENT].customUIData.value(),
  clientID = get.clients.currentClient.clientID.value(),
  updateSubmitUISettingsRequest = update.pages[Page.APP_CLIENT].requests
    .submitCustomUIData,
}) => {
  if (!trigger) {
    return;
  }

  updateAppClientTrigger.remove();

  updateSubmitUISettingsRequest.set(AsyncRequestState.PENDING);

  if (!customUIData.logo || !isBinaryBase64(customUIData.logo))
    delete customUIData.logo;

  try {
    await updateAppClientData({
      ui: customUIData,
      clientID,
    });
    updateSubmitUISettingsRequest.set(AsyncRequestState.FULFILLED);
  } catch (error) {
    updateSubmitUISettingsRequest.set(AsyncRequestState.FAILED);
  }
};
