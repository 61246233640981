import { Page, PageStates, TypeOfSettings } from "../../../types";
import { http } from "../../../services/http";
import { getAppClientById } from "../../../services/api";

export const getCurrentAppClientSettings: producer = async ({
  getCurrentClientTrigger = observe.clients.triggers.getCurrentClient,
  updateGetCurrentClientTrigger = update.clients.triggers.getCurrentClient,
  updateCurrentClient = update.clients.currentClient,
  currentClientId = get.clients.currentClientId.value(),
  settingsVariant = observe.pages[Page.APP_CLIENT].settingsVariant,
  settingsVariantTrigger = update.pages[Page.APP_CLIENT].settingsVariant,
  updateAppClientPageState = update.pages[Page.APP_CLIENT].state,
}) => {
  if (!getCurrentClientTrigger) {
    return;
  }
  updateGetCurrentClientTrigger.remove();

  try {
    const appClientData = await getAppClientById(currentClientId);
    updateCurrentClient.set(appClientData);
    updateAppClientPageState.set(PageStates.INITIALISED);

    if (!settingsVariant) {
      settingsVariantTrigger.set(TypeOfSettings.APP_CLIENT_SETTINGS);
    }
  } catch (error) {
    updateAppClientPageState.set(PageStates.FAILED);
  }

  return () => {
    updateAppClientPageState.remove();
  };
};

export const reactToCurrentClientChanges: producer = async ({
  currentClientId = observe.clients.currentClientId,
  updateCurrentClient = update.clients.currentClient,
}) => {
  if (!currentClientId) {
    updateCurrentClient.remove();
  }
};
