export enum MessageConstants {
  OpenIDDesc = "Requests the ID token with basic user identity information.",
  EmailDesc = "Accesses the user's email address.",
  ProfileDesc = "Requests additional user profile information.",
  VerifiedNationalIdDesc = "Provides the Verified national ID of the user.",
  SignInSignOutDesc = "Enter your callback URLs below that you will include in your sign in and sign out requests.",
  CallbackUrlsTooltip = "The URL to which Verified will redirect users after successful sign-in or sign-out. It should be capable of handling the response from the OIDC provider.",
  AuthorizationCodeDesc = "A secure OAuth 2.0 flow exchanging user credentials for an access token.",
  ImplicitDesc = "A flow granting access token directly, suitable for a client-side app.",
  HybridDesc = "A combo of authorization code and implicit flows, allows receiving tokens via browser and backend.",
  OAuthInfoText = "Select the OAuth flows and scopes enabled for this app.",
  OAuthTooltip = "A required authorization protocol for limited access to user accounts, enabling your app to access Verified user data.",
  DefaultAuthProviderMethod = "If no identity provider is specified in the request for initializing the authentication session, this authentication provider will be used.",
  NoIdentityProviderSelected = "Minimum 1 identity provider must be selected.",
}
