import React from "react";
import { EngineUpdateType, TypeOfSettings } from "../../types";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import styles from "./styles.module.css";
import { Page } from "../../types";

interface EngineProps {
  settingsVariantTrigger: EngineUpdateType;
  settingsVariant: string;
}

export const ToggleSettingsType: view = ({
  settingsVariantTrigger = update.pages[Page.APP_CLIENT].settingsVariant,
  settingsVariant = observe.pages[Page.APP_CLIENT].settingsVariant,
}: EngineProps) => (
  <Breadcrumb>
    <>
      <h1
        className={[
          styles.navLink,
          settingsVariant === TypeOfSettings.APP_CLIENT_SETTINGS &&
            styles.active,
        ].join(" ")}
        onClick={() =>
          settingsVariantTrigger.set(TypeOfSettings.APP_CLIENT_SETTINGS)
        }
      >
        App Client Settings
      </h1>
      <h1
        className={[
          styles.navLink,
          settingsVariant === TypeOfSettings.OIDC_UI_SETTINGS && styles.active,
        ].join(" ")}
        onClick={() =>
          settingsVariantTrigger.set(TypeOfSettings.OIDC_UI_SETTINGS)
        }
      >
        UI settings
      </h1>
    </>
  </Breadcrumb>
);
