import { Button } from "@c11/components";
import React, { ReactFragment, useMemo } from "react";
import {
  ArrowRight,
  DesktopIcon,
  MobileIcon,
  MobilePhone,
  MobileWithQrCode,
} from "../../../components/icons";
import styles from "./styles.module.css";

type ScreenSize = "mobile" | "desktop";

const MobileOptions: React.FC<{
  textColor: string;
}> = ({ textColor }) => {
  return (
    <div
      className={`${styles.methodsContainer} ${styles.methodsContainerMobile} `}
    >
      <div className={styles.methodCard}>
        <MobilePhone />
        <Button className={styles.mobileBtn}>
          <span style={{ color: textColor }}>Mobile BankID on this device</span>
          <ArrowRight />
        </Button>
      </div>
      <div className={styles.methodCard}>
        <MobileWithQrCode />
        <Button className={styles.mobileBtn}>
          <span style={{ color: textColor }}>
            Mobile BankID on another device
          </span>
          <ArrowRight />
        </Button>
      </div>
    </div>
  );
};

const DesktopOptions: React.FC<{ navbarColor: string }> = ({ navbarColor }) => {
  return (
    <div
      className={`${styles.methodsContainer} ${styles.methodsContainerDesktop} `}
    >
      <div className={styles.methodCard}>
        <DesktopIcon />
        <button
          style={{ backgroundColor: navbarColor, borderColor: navbarColor }}
          className={styles.desktopBtn}
        >
          BankID on this device
        </button>
      </div>

      <div className={styles.methodCard}>
        <MobileIcon />
        <button
          style={{ backgroundColor: navbarColor, borderColor: navbarColor }}
          className={styles.desktopBtn}
        >
          {" "}
          Mobile BankId
        </button>
      </div>
    </div>
  );
};

export const PreviewBankidScreen: React.FC<{
  navbarColor: string;
  textColor: string;
  logo?: string;
  screenSize?: ScreenSize;
}> = ({
  navbarColor = "#218bcb",
  textColor = "#218bcb",
  logo,
  screenSize = "desktop",
}) => {
  const CardsMapper: Record<ScreenSize, JSX.Element> = useMemo(() => {
    return {
      mobile: <MobileOptions textColor={textColor} />,
      desktop: <DesktopOptions navbarColor={navbarColor} />,
    };
  }, [textColor]);

  return (
    <div className={styles.bankidPreviewWrapper}>
      <header style={{ backgroundColor: navbarColor }}>
        <div className={styles.navContainer}>
          <div
            className={styles.logo}
            style={{ background: `url(${logo}) no-repeat center` }}
          ></div>
        </div>
      </header>

      <div className={styles.content}>
        <h2 className={styles.title}>Authenticate with BankID</h2>
        <p className={styles.subtitle}>
          In order to review the documents please sign in with your personal
          BankID
        </p>
        {CardsMapper[screenSize]}
      </div>
    </div>
  );
};

export const PreviewMobileBankidScreen: React.FC<{
  navbarColor: string;
  textColor: string;
  logo?: string;
}> = ({ navbarColor, textColor, logo }) => {
  return (
    <div className={styles.bankidPreviewWrapper}>
      <header style={{ backgroundColor: navbarColor }}>
        <div className={styles.navContainer}>
          <div
            className={styles.logo}
            style={{ background: `url(${logo}) no-repeat center` }}
          ></div>
        </div>
      </header>

      <div className={styles.content}>
        <h2 className={styles.title}>Authenticate with BankID</h2>
        <p className={styles.subtitle}>
          In order to review the documents please sign in with your personal
          BankID
        </p>
        <div className={styles.methodsContainer}>
          <div className={styles.methodCard}>
            <DesktopIcon />
            <Button>BankID on this device</Button>
          </div>

          <div className={styles.methodCard}>
            <MobileIcon />
            <Button> Mobile BankId</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
