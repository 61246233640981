import { Checkbox } from "@c11/components";
import Select, { SingleValue } from "react-select";
import React, { useEffect, useState } from "react";
import { EngineUpdateType, Page } from "../../../../types";
import { toggleElemenetInArray } from "../../../../utils/toggleItemInArray";
import styles from "../../styles.module.css";
import Tooltip from "../../../../components/tooltip/Tooltip";
import { MessageConstants } from "../MessageConstants";
import TextBox from "../../../../components/textBox/TextBox";
import { TextVariants } from "../../../../types/TextVariants";

interface Option {
  value: string;
  label: string;
}

interface Props {
  availableMethods: State["pages"][Page.APP_CLIENT]["availableAuthMethods"];
  authenticationMethods: State["pages"][Page.APP_CLIENT]["formData"]["authenticationMethods"];
  selectedDefaultAuthMethod: State["pages"][Page.APP_CLIENT]["formData"]["defaultAuthenticationMethod"];
  updateAuthenticationMethods: EngineUpdateType;
  updateDefaultAuthMethod: EngineUpdateType;
  shouldDisableSubmit: EngineUpdateType;
}

export const IdentityProviders: view = ({
  updateAuthenticationMethods = update.pages[Page.APP_CLIENT].formData
    .authenticationMethods,
  authenticationMethods = observe.pages[Page.APP_CLIENT].formData
    .authenticationMethods,
  selectedDefaultAuthMethod = observe.pages[Page.APP_CLIENT].formData
    .defaultAuthenticationMethod,
  updateDefaultAuthMethod = update.pages[Page.APP_CLIENT].formData
    .defaultAuthenticationMethod,
  availableMethods = observe.pages[Page.APP_CLIENT].availableAuthMethods,
  shouldDisableSubmit = update.pages[Page.APP_CLIENT].disableSubmit,
}: Props) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [defaultAuthMethodValue, setDefaultAuthMethodValue] = useState<
    Option | undefined | null
  >({
    value: selectedDefaultAuthMethod,
    label: selectedDefaultAuthMethod,
  });

  useEffect(() => {
    setOptions(getCheckedMethods());
  }, [authenticationMethods]);

  useEffect(() => {
    const defaultMethodExistAmongSelected = authenticationMethods.find(
      (authMethod) => authMethod === selectedDefaultAuthMethod
    );

    if (!defaultMethodExistAmongSelected) {
      setDefaultAuthMethodValue(null);
      shouldDisableSubmit.set(true);
    } else {
      shouldDisableSubmit.set(false);
    }
  }, [options]);

  const handleSetDefaultAuthMethod = (selected: SingleValue<Option>) => {
    if (!selected) return;
    setDefaultAuthMethodValue(selected);
    updateDefaultAuthMethod.set(selected.value);
  };

  const isMethodChecked = (method: string) =>
    authenticationMethods?.includes(method) || false;

  const getCheckedMethods = () => {
    const methods = availableMethods.filter((method: string) =>
      isMethodChecked(method)
    );
    return methods.map((method: string) => ({ value: method, label: method }));
  };

  const handleTickCheckBox = (method: string) => {
    updateAuthenticationMethods.set(
      toggleElemenetInArray(authenticationMethods, method)
    );
  };

  return (
    <>
      <div>
        <h2 className="grey">Enabled Identity Providers</h2>
      </div>
      {options.length < 1 && (
        <TextBox
          variant={TextVariants.WARNING}
          label={MessageConstants.NoIdentityProviderSelected}
        />
      )}
      <div className={styles.idProvidersList}>
        {availableMethods.map((method: string, i: number) => (
          <div key={i} className={styles.idProviderListItem}>
            <Checkbox
              isChecked={isMethodChecked(method)}
              onClick={() => handleTickCheckBox(method)}
              key={method}
            >
              {method}
            </Checkbox>
          </div>
        ))}
      </div>
      <div>
        <Tooltip message={MessageConstants.DefaultAuthProviderMethod} showIcon>
          <h4 className="grey">Default identity provider</h4>
        </Tooltip>
      </div>
      {options.length > 0 && (
        <Select
          value={defaultAuthMethodValue}
          options={options}
          onChange={(selected: SingleValue<Option>) => {
            handleSetDefaultAuthMethod(selected);
          }}
        />
      )}
    </>
  );
};
