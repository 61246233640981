// export enum VeSecuredMethods {
//   Norwegian_BankID = "Norwegian BankID",
//   Swedish_BankID = "Swedish BankID",
//   Finnish_BankID = "Finnish BankID",
//   Mobiilivarmenne = "Mobiilivarmenne",
//   NemID = "NemID",
//   Estonian_eID = "Estonian eID",
//   Latvian_eID = "Latvian eID",
//   Lithuanian_eID = "Lithuanian eID",
//   Belgian_eID = "Belgian eID",
//   Freja = "Freja",
//   Passport_Reader = "Passport Reader",
//   SMS = "SMS",
// }

export enum VeSecuredMethods {
  Norwegian_BankID = "bankid-no",
  Swedish_BankID = "bankid-se",
  // Finnish_BankID = "bankid-fi",
  // Mobiilivarmenne = "Mobiilivarmenne",
  // NemID = "NemID",
  // Estonian_eID = "Estonian eID",
  // Latvian_eID = "Latvian eID",
  // Lithuanian_eID = "Lithuanian eID",
  // Belgian_eID = "Belgian eID",
  // Freja = "Freja",
  // Passport_Reader = "passport-reader",
  // SMS = "sms",
}
