import styles from "./styles.module.css";
import { Button } from "@c11/components";
import * as Producers from "../producers";
import { AsyncRequestState, EngineUpdateType, Page } from "../../../types";
import { isFileSizeLessThanMax } from "../../../services/checkFileSize";
import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import { PreviewBankidScreen } from "./PreviewBankidScreen";
import { useState } from "react";
import { debounce } from "lodash";
import { FormCard } from "../../../components/formCard/FormCard";
import { isBinaryBase64 } from "../../../services/isBinary";
import Tooltip from "../../../components/tooltip/Tooltip";

interface EngineTypes {
  areUISettingsChangesMade: boolean;
  updateUISettingsTrigger: EngineUpdateType;
  primaryColor: string;
  updatePrimaryColor: EngineUpdateType;
  secondaryColor: string;
  updateSecondaryColor: EngineUpdateType;
  updateBase64Logo: EngineUpdateType;
  base64Logo: string;
  requestStatus: string;
}

export const OidcUISettings: view = ({
  areUISettingsChangesMade = observe.pages[Page.APP_CLIENT]
    .areUISettingsChangesMade,
  updateUISettingsTrigger = update.pages[Page.APP_CLIENT].triggers
    .submitUISettingsData,
  primaryColor = observe.pages[Page.APP_CLIENT].customUIData.colors.primary,
  updatePrimaryColor = update.pages[Page.APP_CLIENT].customUIData.colors
    .primary,
  secondaryColor = observe.pages[Page.APP_CLIENT].customUIData.colors.secondary,
  updateSecondaryColor = update.pages[Page.APP_CLIENT].customUIData.colors
    .secondary,
  base64Logo = observe.pages[Page.APP_CLIENT].customUIData.logo,
  updateBase64Logo = update.pages[Page.APP_CLIENT].customUIData.logo,
  requestStatus = observe.pages[Page.APP_CLIENT].requests.submitCustomUIData,
}: EngineTypes) => {
  const handleColorChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>, colorType: string) => {
      switch (colorType) {
        case "primary":
          updatePrimaryColor.set(event.target.value);
          if (!secondaryColor) updateSecondaryColor.set(event.target.value);
          break;
        case "secondary":
          updateSecondaryColor.set(event.target.value);
          break;
      }
    },
    300
  );

  const handleSetBase64Logo = (file: File, event: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result as string;
      if (!isBinaryBase64(base64String)) {
        immediateToast("error", {
          message: `Image encoding has failed, please try again or upload another PNG file.`,
          timeout: 10000,
          closeOnClick: true,
          position: "topRight",
        });
      } else {
        updateBase64Logo.set(base64String);
      }
      event.target.value = ""; // Resets the image to allow upload again
    };
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type !== "image/png") {
        event.target.value = "";
        immediateToast("error", {
          message: `${file.type
            .split("/")[1]
            .toUpperCase()} file extension is not allowed. You can only upload a PNG file`,
          timeout: 10000,
          closeOnClick: true,
          position: "topRight",
        });
        return;
      }
      if (isFileSizeLessThanMax(file, 227.3)) {
        file && void handleSetBase64Logo(file, event);
        return;
      } else {
        event.target.value = ""; // Resets the image
        immediateToast("error", {
          message:
            "The file is too large. PNGs larger than 0.2 MB are not supported",
          timeout: 10000,
          closeOnClick: true,
          position: "topRight",
        });
      }
    }
  };

  const handleSubmit = () => {
    if (!secondaryColor) updateSecondaryColor.set(primaryColor);
    updateUISettingsTrigger.set(true);
  };

  const [showPreview, setShowPreview] = useState<boolean>(false);

  //TODO: remove this in the future
  let imageLogo =
    base64Logo !== "ui/logos/default.png"
      ? base64Logo
      : `https://app.verified.eu/assets/logo.png`;

  return (
    <div className={styles.flexContainer}>
      <h2 className="grey">Custom UI settings</h2>
      <form className={styles.flexContainer}>
        <FormCard>
          <span className={styles.textContainer}>Color (primary)</span>
          <div className={styles.formSectionInput}>
            <input
              type="color"
              defaultValue={primaryColor}
              onChange={(e) => handleColorChange(e, "primary")}
            />
            {primaryColor && (
              <span className={styles.textContainer}>{primaryColor}</span>
            )}
          </div>
        </FormCard>
        <FormCard>
          <span className={styles.textContainer}>Color (text)</span>
          <div className={styles.formSectionInput}>
            <input
              type="color"
              defaultValue={secondaryColor ? secondaryColor : primaryColor}
              onChange={(e) => handleColorChange(e, "secondary")}
            />
            {primaryColor && (
              <span className={styles.textContainer}>
                {secondaryColor ? secondaryColor : primaryColor}
              </span>
            )}
            <span className={styles.textContainer}>
              According to color accessibility guidelines, use a color that
              provides high contrast to white.
            </span>
          </div>
        </FormCard>
        <FormCard>
          <span className={styles.textContainer}>Logo (max 0.2MB)</span>
          <div className={styles.formSectionInput}>
            <div>
              <label className={styles.fileInput}>
                <input
                  type="file"
                  accept="image/png"
                  onChange={handleImageChange}
                />
                Choose PNG
              </label>
            </div>

            <br />
            <div>
              {imageLogo ? (
                <img
                  style={{ maxWidth: "50px" }}
                  src={imageLogo}
                  alt="Preview"
                />
              ) : (
                <p>Loading preview...</p>
              )}
            </div>
          </div>
        </FormCard>

        <div className={styles.buttonContainer}>
          <div className={styles.previewButton}>
            <Button
              variant="secondary"
              className={styles.showPreviewBtn}
              onClick={() => setShowPreview(!showPreview)}
            >
              {!showPreview ? "Show  preview" : "Hide  preview"}
            </Button>
          </div>
          <div>
            <Button
              isLoading={requestStatus === AsyncRequestState.PENDING}
              isDisabled={
                !(requestStatus === AsyncRequestState.UNINITIALISED) ||
                !areUISettingsChangesMade
              }
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
      {showPreview && (
        <div className={styles.previewContainer}>
          <div className={styles.previewCard}>
            <p>Desktop</p>
            <PreviewBankidScreen
              navbarColor={primaryColor}
              textColor={secondaryColor}
              logo={imageLogo}
            />
          </div>
          <div className={styles.previewCard}>
            <p>Mobile</p>
            <PreviewBankidScreen
              navbarColor={primaryColor}
              textColor={secondaryColor}
              logo={imageLogo}
              screenSize="mobile"
            />
          </div>
        </div>
      )}
    </div>
  );
};

OidcUISettings.producers(Producers);
