export const AUTH_TOKEN_KEY = "id_token";

//TODO: REMOVE this; it's just a quick workaround for now
export const IDENTITY_PROVIDERS_TO_IGNORE = [
  "ee-id",
  "lv-id",
  "lt-id",
  "be-id",
  "pt-id",
  "rs-id",
  "zealid",
  "freja",
  "mobiilivarmenne",
  "sms",
];
