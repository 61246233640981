export const toggleElemenetInArray = (
  array?: Array<unknown>,
  value: unknown
) => {
  let copyArray;
  if(!array) {
    copyArray = [value];
    return copyArray;
  } 

  copyArray = JSON.parse(JSON.stringify(array));
  const index = copyArray.indexOf(value);
  if (index === -1) {
    copyArray.push(value);
  } else {
    copyArray.splice(index, 1);
  }
  return copyArray;
};
