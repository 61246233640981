import { http } from "../../../services/http";
import { AsyncRequestState } from "../../../types";

export interface AppClient {
  namespace: string;
  clientID: string;
  name: string;
}

export const submitDeleteAppClient: producer = async ({
  deleteAppClientTrigger = observe.organization.triggers.deleteAppClientTrigger,
  updateAppClientTrigger = update.organization.triggers.deleteAppClientTrigger,
  currentClientId = get.clients.currentClientId.value(),
  updateDeleteAppClientRequest = update.clients.requests.deleteAppClient,
  updateCurrentClientId = update.clients.currentClientId,
  items = get.organization.items.value(),
  updateOrganizationItems = update.organization.items,
  updateShowDeleteModal = update.organization.showDeleteModal,
}) => {
  if (!deleteAppClientTrigger) {
    return;
  }
  updateAppClientTrigger.remove();
  updateDeleteAppClientRequest.set(AsyncRequestState.PENDING);
  try {
    updateShowDeleteModal.set(false);
    await http.delete(
      `api/ve-secure-settings/client-credentials/${currentClientId}`
    );
    updateOrganizationItems.set(
      items.filter((el: AppClient) => el.clientID !== currentClientId)
    );
    updateDeleteAppClientRequest.set(AsyncRequestState.FULFILLED);
    updateCurrentClientId.remove();
    updateDeleteAppClientRequest.set(AsyncRequestState.UNINITIALISED);
  } catch (error) {
    updateDeleteAppClientRequest.set(AsyncRequestState.FAILED);
  }
};
