import { Dashboard } from "./pages/Dashboard/Dashboard";
import { AppClientManagement } from "./pages/OrganizationClientManagement/AppClientPage";
import { Toggle } from "@c11/components.conditionals";

export const App: view = ({
  currentPage = observe.pages.currentPage,
}) => {
  return (
    <Toggle
      value={!!currentPage}
      condition={true}
      on={<AppClientManagement />}
      off={<Dashboard />}
    />
  );
};
