import { Button } from "@c11/components";
import React from "react";
import { AsyncRequestState, EngineUpdateType, Page } from "../../../types";
import styles from "../styles.module.css";

interface Props {
  areChangesMade: boolean;
  triggerResetForm: EngineUpdateType;
  updateAppClientTrigger: EngineUpdateType;
  submitAppClientRequestState: AsyncRequestState;
  submitIsDisabled: boolean;
}

export const FormCTAs: view = ({
  areChangesMade = observe.pages[Page.APP_CLIENT].areFormChangesMade,
  triggerResetForm = update.pages[Page.APP_CLIENT].triggers.resetFormData,
  updateAppClientTrigger = update.pages[Page.APP_CLIENT].triggers
    .submitAppClientData,
  submitAppClientRequestState = observe.pages[Page.APP_CLIENT].requests
    .submitAppClient,
  submitIsDisabled = observe.pages[Page.APP_CLIENT].disableSubmit,
}: Props) => {
  return (
    <div className={styles.actionsWrapper}>
      {submitAppClientRequestState !== AsyncRequestState.PENDING &&
        submitAppClientRequestState !== AsyncRequestState.FULFILLED && (
          <Button
            isDisabled={!areChangesMade}
            onClick={() => triggerResetForm.set(Date.now())}
          >
            Cancel
          </Button>
        )}
      {submitAppClientRequestState !== AsyncRequestState.FULFILLED && (
        <Button
          isDisabled={!areChangesMade || submitIsDisabled}
          isLoading={submitAppClientRequestState === AsyncRequestState.PENDING}
          type="submit"
          onClick={() => updateAppClientTrigger.set(true)}
        >
          Submit
        </Button>
      )}
    </div>
  );
};
