export enum Page {
  ROOT = "root",
  DASHBOARD = "dashboard",
  APP_CLIENT = "app client",
  ORGANIZATION = "organization",
}

export enum TypeOfSettings {
  OIDC_UI_SETTINGS = "OIDC UI settings",
  APP_CLIENT_SETTINGS = "App client settings",
}

export enum PageStates {
  LOADING = "loading",
  INITIALISED = "initialised",
  FAILED = "failed",
}
export interface CustomUIData {
  showSidebar: boolean;
  logo: string;
  colors: UIColors;
}

type UIColors = { primary: string; secondary: string };
