export enum LanguageMap {
  bg_BG = "Bulgarian (Български)",
  cs_CZ = "Czech (Čeština)",
  da_DK = "Danish (Dansk)",
  de_AT = "Austrian German (Österreichisches Deutsch)",
  de_DE = "German (Deutsch)",
  de_LI = "Liechtenstein German (Liechtensteiner Deutsch)",
  el_CY = "Cypriot Greek (Ελληνικά, Κύπρος)",
  el_GR = "Greek (Ελληνικά)",
  en_EN = "English (English)",
  en_IE = "Irish English (English)",
  en_US = "American English (English)",
  es_ES = "Spanish (Español)",
  et_EE = "Estonian (Eesti)",
  fi_FI = "Finnish (Suomi)",
  fr_CH = "Swiss French (Français de Suisse)",
  fr_FR = "French (Français)",
  fr_LU = "Luxembourgish (Lëtzebuergesch)",
  hr_HR = "Croatian (Hrvatski)",
  hu_HU = "Hungarian (Magyar)",
  is_IS = "Icelandic (Íslenska)",
  it_IT = "Italian (Italiano)",
  lt_LT = "Lithuanian (Lietuvių)",
  lv_LV = "Latvian (Latviešu)",
  mt_MT = "Maltese (Malti)",
  nb_NO = "Norwegian Bokmål (Norsk Bokmål)",
  nl_BE = "Belgian Dutch (Nederlands, België)",
  nl_NL = "Dutch (Nederlands)",
  pl_PL = "Polish (Polski)",
  pt_PT = "Portuguese (Português)",
  ro_RO = "Romanian (Română)",
  sk_SK = "Slovak (Slovenčina)",
  sl_SI = "Slovenian (Slovenščina)",
  sv_SE = "Swedish (Svenska)",
  zh_HK = "Cantonese (繁體中文)",
  zh_SG = "Singapore Chinese (繁體中文)",
}
