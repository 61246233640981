import React from "react";
import styles from "./styles.module.css";

type Props = {
  children: JSX.Element;
};

const Breadcrumb = ({ children }: Props) => {
  return <div className={styles.breadCrumbs}>{children}</div>;
};

export default Breadcrumb;
