import React from "react";
import { Divider } from "../../components/divider/Divider";

import {
  Button,
  Checkbox,
  Modal,
  ButtonVariants,
  TextField,
} from "@c11/components";
import { AsyncRequestState, EngineUpdateType, Page } from "../../types";
import styles from "./styles.module.css";
import * as Producers from "./producers";

interface C11EngineProps {
  updateCurrentPage: EngineUpdateType;
  appClientName: string;
  updateAppClientName: EngineUpdateType;
  updateCreateAppClientTrigger: EngineUpdateType;
  appClientId: string;
  appClientSecret: string;
  consent: boolean;
  newAppClientRequest: string;
  secretVisibility: boolean;
  secretIsCopied: boolean;
  tooltipText: string;
  updateConsent: EngineUpdateType;
  closeModal: EngineUpdateType;
  updateSecretVisibility: EngineUpdateType;
  updateSecretIsCopied: EngineUpdateType;
  updateTooltipText: EngineUpdateType;
}

export const CreateAppClientModal: view = ({
  updateCurrentPage = update.pages.currentPage,
  appClientName = observe.clients.appClientName,
  updateAppClientName = update.clients.appClientName,
  updateCreateAppClientTrigger = update.organization.triggers.createAppClient,
  appClientId = observe.clients.currentClientId,
  appClientSecret = observe.clients.currentAppClientSecret,
  consent = observe.organization.isConsentGiven,
  updateConsent = update.organization.isConsentGiven,
  newAppClientRequest = observe.clients.requests.submitNewAppClient,
  closeModal = update.organization.modalIsOpen,
  secretVisibility = observe.clients.currentAppClientSecretVisibility,
  updateSecretVisibility = update.clients.currentAppClientSecretVisibility,
  secretIsCopied = observe.clients.secretIsCopied,
  updateSecretIsCopied = update.clients.secretIsCopied,
  tooltipText = observe.clients.tooltipText,
  updateTooltipText = update.clients.tooltipText,
}: C11EngineProps) => {
  const moveUserToAppClientSettings = () => {
    closeModal.set(false);
    updateCurrentPage.set(Page.APP_CLIENT);
  };

  const toggleSecretVisibility = () => {
    updateSecretVisibility.set(!secretVisibility);
  };

  const stringIsValid = () => {
    if (!appClientName) return false;
    const matchNonEmptyStringRegex = /^\S.*?/;
    const matches = appClientName.match(matchNonEmptyStringRegex);
    if (matches) return true;
    else return false;
  };

  const renderHideSecret = (secret: string) => {
    const chars = secret.split("");
    return (
      <div className={styles.secretsContainer}>
        {chars.map((char, i) => (
          <div key={i} className={styles.dot}></div>
        ))}
      </div>
    );
  };

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(appClientSecret);
      updateSecretIsCopied.set(!0);
      updateTooltipText.set("Copied! ✅");
    } catch (err) {
      updateSecretIsCopied.set(!1);
      console.error("Failed to copy ❌ ", err);
    }
  };

  return (
    <Modal>
      <div className={styles.modalContent}>
        {appClientId ? (
          <div className={styles.modalCopyContent}>
            <h1 className={styles.header}>
              {appClientName} app client was successfully created
            </h1>

            <div className={styles.appClientInfoContainer}>
              <div className={styles.flexContainerColumn}>
                <h3>App client id</h3>
                {appClientId}
              </div>
              <div className={styles.flexContainerColumn}>
                <div className={styles.flexContainer}>
                  <h3 className={styles.clientSecretInfo}>
                    Unique app client secret
                  </h3>
                </div>

                <div className={styles.secretContainerSection}>
                  {secretVisibility ? (
                    <span className={styles.clientSecret}>
                      {appClientSecret}
                    </span>
                  ) : (
                    <span className={styles.clientSecret}>
                      {renderHideSecret(appClientSecret)}
                    </span>
                  )}
                  <Button variant="secondary" onClick={toggleSecretVisibility}>
                    {secretVisibility ? " Hide" : " Show"}
                  </Button>
                </div>
              </div>
              <p className={styles.warningText}>
                You will be able to see this secret only once! Make sure you
                copy it
              </p>

              <button
                className={styles.copy}
                data-message={tooltipText}
                onClick={handleCopy}
                onMouseEnter={() => updateTooltipText.set("Copy to clipboard")}
              >
                Copy
              </button>
            </div>

            <div className={styles.consentContainer}>
              <div className={styles.checkbox}>
                <Checkbox
                  isChecked={consent}
                  onClick={() => {
                    updateConsent.set(!consent);
                  }}
                >
                  <p className={styles.confirmText}>
                    I hereby confirm that I have copied the App Client Secret
                    and I understand that it will be visible only once.
                  </p>
                </Checkbox>
              </div>

              <Button
                isDisabled={!consent}
                onClick={() => moveUserToAppClientSettings()}
              >
                Go to settings
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.modalCopyContent}>
            <h3 className={styles.heading}>Create new app client</h3>
            <Divider size="lg" />
            <p>
              Please enter the name of the app client that you wish to create
            </p>
            <TextField
              value={appClientName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateAppClientName.set(e.target.value);
              }}
            />
            <Divider size="lg" />
            <div className={styles.section}>
              <Button
                isLoading={newAppClientRequest === AsyncRequestState.PENDING}
                onClick={() => updateCreateAppClientTrigger.set(Date.now())}
                isDisabled={!stringIsValid()}
              >
                Create app client
              </Button>
              <Button
                variant={ButtonVariants.secondary}
                onClick={() => closeModal.set(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

CreateAppClientModal.producers(Producers);
