// TODO replace with style guide tooltip
import React, { useState } from "react";
import styles from "./styles.module.css";
import TooltipInfoIcon from "../tooltipInfoIcon/TooltipInfoIcon";

type TooltipProps = {
  message: string;
  children: React.ReactNode;
  showIcon?: boolean;
};

const Tooltip: React.FC<TooltipProps> = ({ message, children, showIcon }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className={styles.tooltipContainer}>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={styles.tooltipContents}
      >
        {children}
        {showIcon && <TooltipInfoIcon />}
      </div>
      <span
        className={`${styles.tooltip} ${
          hover ? styles.visible : styles.invisible
        }`}
        style={{ maxWidth: "13vw" }}
      >
        {message}
      </span>
    </div>
  );
};

export default Tooltip;
