import React from "react";

export const DesktopIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="148"
      height="121"
      viewBox="0 0 148 121"
      fill="none"
    >
      <rect
        x="59.8628"
        y="100.592"
        width="27.852"
        height="13.2162"
        fill="#E1E1E1"
        stroke="#999999"
        strokeWidth="2"
      />
      <rect
        x="40.1313"
        y="113.798"
        width="67.3143"
        height="6"
        rx="3"
        stroke="#999999"
        strokeWidth="2"
      />
      <rect
        x="1.27246"
        y="1.73218"
        width="145.032"
        height="98.8749"
        rx="3"
        stroke="#999999"
        strokeWidth="2"
      />
      <path
        d="M1.27246 80.9978H146.305V97.6071C146.305 99.264 144.962 100.607 143.305 100.607H4.27247C2.61561 100.607 1.27246 99.264 1.27246 97.6071V80.9978Z"
        fill="white"
        stroke="#999999"
        strokeWidth="2"
      />
      <path
        d="M64.5884 43.0898H82.9879C83.6626 43.0898 84.2145 42.5212 84.2145 41.8262L84.2149 26.6606C84.2149 25.9655 83.663 25.3969 82.9884 25.3969H80.5353L80.5349 22.2375C80.5349 18.4079 77.5051 15.2864 73.7882 15.2864C70.0717 15.2864 67.0419 18.4079 67.0419 22.2375V25.3969H64.5888C63.9142 25.3969 63.3623 25.9656 63.3623 26.6606V41.8262C63.3619 42.5213 63.9138 43.0898 64.5884 43.0898ZM69.495 22.2372C69.495 19.798 71.4211 17.8136 73.7882 17.8136C76.1557 17.8136 78.0817 19.798 78.0817 22.2372V25.3967H69.4955L69.495 22.2372ZM65.8154 27.9241H81.7616V40.562H65.8154V27.9241Z"
        fill="#444444"
      />
      <path
        d="M57.1166 51.9421V56.8982L61.9615 55.2091L62.8505 57.676L58.0056 59.254L61.0059 63.4321L58.8056 65.0101L55.8942 60.6763L52.9606 65.0101L50.6937 63.4321L53.7385 59.254L48.8491 57.676L49.7381 55.2091L54.5386 56.9426V51.9421H57.1166Z"
        fill="#444444"
      />
      <path
        d="M75.0556 51.9421V56.8982L79.9005 55.2091L80.7894 57.676L75.9445 59.254L78.9448 63.4321L76.7446 65.0101L73.8332 60.6763L70.8996 65.0101L68.6327 63.4321L71.6774 59.254L66.7881 57.676L67.6771 55.2091L72.4775 56.9426V51.9421H75.0556Z"
        fill="#444444"
      />
      <path
        d="M92.994 51.9421V56.8982L97.8389 55.2091L98.7279 57.676L93.883 59.254L96.8833 63.4321L94.6831 65.0101L91.7717 60.6763L88.8381 65.0101L86.5712 63.4321L89.6159 59.254L84.7266 57.676L85.6155 55.2091L90.416 56.9426V51.9421H92.994Z"
        fill="#444444"
      />
    </svg>
  );
};
