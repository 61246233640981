import { debug } from "@c11/engine.patterns";
import { render } from "@c11/engine.react";
import { EventNames } from "@c11/engine.types";
import { engine, producers, pathFn } from "@c11/engine.runtime";
import { connectToDashboard } from "@c11/engine.dashboard";
import { App } from "./App";
import "./global";
import * as Producers from "./producers";

type AppConfig = any;

export const createApp = (config: AppConfig) => {
  console.log(config, "CONFIGURATIA");

  const { path, element, services } = config;
  const {
    EventBus,
    CompanyService,
    IdentityService,
    EnvelopeService,
    TranslationService,
    RoutingService,
  } = services;

  const state = {
    path,
    services: {
      company: CompanyService,
      identity: IdentityService,
      envelope: EnvelopeService,
      routing: RoutingService,
      translation: TranslationService,
      eventBus: () => EventBus,
    },
  };

  if (!window.identityService) {
    window.identityService = services.IdentityService;
  }

  console.log("companyService", services.CompanyService.getInstance());

  window.companyService = services.CompanyService;

  let app;

  let producersArr = [...Object.values(Producers)];
  let onEvents;

  if (process.env.NODE_ENV === "development") {
    const dashboard = connectToDashboard();
    (onEvents = {
      [EventNames.STATE_UPDATED]: dashboard.send,
      [EventNames.PATCH_APPLIED]: dashboard.send,
    }),
      producersArr.push(debug);
    const receiveUpdates: producer = ({ updatePath = update }) => {
      dashboard.on((event) => {
        event.data.text().then((x) => {
          const op = JSON.parse(x);
          const result = updatePath(pathFn(...op.path));
          result.set(op.value);
        });
      });
    };
    producersArr.push(receiveUpdates);
  }
  let containerElement;
  if (!element) {
    containerElement = document.createElement("div");
    containerElement.id = "client-management";
    document.body.appendChild(containerElement);
  } else {
    containerElement = element;
  }

  app = engine({
    onEvents,
    state,
    use: [
      producers(producersArr),

      render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        containerElement,
        {
          debug: false,
        }
      ),
    ],
  });
  return app;
};
