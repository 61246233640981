import { isEqual } from "lodash";
import {
  convertOidcDTOToStateData,
  convertOidcStateDataToDTO,
} from "../../../utils/dto";
import { http } from "../../../services/http";
import {
  IDENTITY_PROVIDERS_TO_IGNORE,
  Page,
  PageStates,
  VeSecuredMethods,
} from "../../../types";

export const initializeFormSettingsPageState: producer = async ({
  updateAppClientPageFormData = update.pages[Page.APP_CLIENT].formData,
  currentClient = observe.clients.currentClient,
  updateGetMethodsTrigger = update.pages[Page.APP_CLIENT]
    .getAvailableIdentityMethodsTrigger,
  settingsPageState = get.pages[Page.APP_CLIENT].settingsPageSate.value(),

  updateAppClientPageState = update.pages[Page.APP_CLIENT].settingsPageSate,
}) => {
  if (!currentClient || settingsPageState) {
    return;
  }
  updateAppClientPageState.set(PageStates.LOADING);
  updateAppClientPageFormData.set(
    convertOidcDTOToStateData(currentClient.oidc)
  );
  updateGetMethodsTrigger.set(Date.now());
  return () => {
    updateAppClientPageFormData.remove();
  };
};

export const getAvailableIdentityMethods: producer = async ({
  trigger = observe.pages[Page.APP_CLIENT].getAvailableIdentityMethodsTrigger,
  updateTrigger = update.pages[Page.APP_CLIENT]
    .getAvailableIdentityMethodsTrigger,
  updateAvailableMethods = update.pages[Page.APP_CLIENT].availableAuthMethods,
  updateAvailableLanguages = update.pages[Page.APP_CLIENT].availableLanguages,
  updateAvailableScopes = update.pages[Page.APP_CLIENT].availableAuthScopes,
  updateAvailableFlows = update.pages[Page.APP_CLIENT].availableAuthFlows,

  updateAppClientPageState = update.pages[Page.APP_CLIENT].settingsPageSate,
}) => {
  if (!trigger) {
    return;
  }
  try {
    updateTrigger.remove();
    const { data } = await http.get(
      "api/secure/oidc/.well-known/openid-configuration"
    );
    const {
      providers_supported,
      locales_supported,
      grant_types_supported,
      scopes_supported,
    } = data;
    updateAvailableMethods.set(
      providers_supported.filter(
        (provider: string) => !IDENTITY_PROVIDERS_TO_IGNORE.includes(provider)
      )
    );
    updateAvailableLanguages.set(locales_supported);
    updateAvailableScopes.set(scopes_supported);
    updateAvailableFlows.set(grant_types_supported);

    updateAppClientPageState.set(PageStates.INITIALISED);
  } catch (error) {
    updateAppClientPageState.set(PageStates.FAILED);
  }
  return () => {
    updateAppClientPageState.remove();
  };
};

export const detectFormChanges: producer = ({
  formData = observe.pages[Page.APP_CLIENT].formData,
  currentClient = observe.clients.currentClient.oidc,
  updateAreChangesMade = update.pages[Page.APP_CLIENT].areFormChangesMade,
  settingsPageState = get.pages[Page.APP_CLIENT].settingsPageSate.value(),
}) => {
  if (settingsPageState === PageStates.INITIALISED) {
    const dtoData = convertOidcStateDataToDTO(formData);
    updateAreChangesMade.set(!isEqual(dtoData, currentClient));
  }
};

export const resetFormData: producer = ({
  updateEntireFormData = update.pages[Page.APP_CLIENT].formData,
  currentClient = get.clients.currentClient.value(),
  trigger = observe.pages[Page.APP_CLIENT].triggers.resetFormData,
  triggerResetForm = update.pages[Page.APP_CLIENT].triggers.resetFormData,
}) => {
  if (!trigger) {
    return;
  }
  triggerResetForm.remove();
  updateEntireFormData.set(convertOidcDTOToStateData(currentClient.oidc));
};

//NOT NEEDED FOR NOW

// export const checkIfAllVeSecureProvidersAreSelected: producer = ({
//   updateAuthenticationMethods = update.pages[Page.APP_CLIENT].formData
//     .authenticationMethods,
//   authenticationMethods = observe.pages[Page.APP_CLIENT].formData
//     .authenticationMethods,
//   updateSelectedProviders = update.pages[Page.APP_CLIENT]
//     .areAllOidcProvidersSelected,
// }) => {
//   const authenticationMethodsCount = authenticationMethods?.length || 0;
//   if (authenticationMethodsCount === 0) {
//     updateAuthenticationMethods.remove();
//     return;
//   }
//   const veSecuredProvidersCount = Object.values(VeSecuredMethods).length;

//   updateSelectedProviders.set(
//     authenticationMethodsCount === veSecuredProvidersCount &&
//       authenticationMethodsCount > 0 &&
//       veSecuredProvidersCount > 0
//   );
// };
