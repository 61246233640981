export const MagnifyingGlass = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66985 14.3397C11.3535 14.3397 14.3397 11.3535 14.3397 7.66985C14.3397 3.98619 11.3535 1 7.66985 1C3.98619 1 1 3.98619 1 7.66985C1 11.3535 3.98619 14.3397 7.66985 14.3397Z"
      stroke="#444444"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9976 17L12.3828 12.3853"
      stroke="#444444"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
