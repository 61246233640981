import styles from "./styles.module.css";

export const Loader = ({ className = "" }) => (
  <div
    className={`${className} ${styles.loader} ${styles.large} ${styles.full}`}
  >
    <div className={styles["loader-spinner"]}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16ZM2.95 16C2.95 23.2073 8.79268 29.05 16 29.05C23.2073 29.05 29.05 23.2073 29.05 16C29.05 8.79268 23.2073 2.95 16 2.95C8.79268 2.95 2.95 8.79268 2.95 16Z"
          fill="#D5D5D5"
        />
        <path
          d="M30.025 16C30.5635 16 31.0033 15.563 30.9683 15.0257C30.812 12.6243 30.0798 10.2907 28.8275 8.22462C27.4063 5.88008 25.3698 3.96987 22.9391 2.70157C20.5085 1.43326 17.7768 0.855375 15.0407 1.0307C12.3047 1.20603 9.66908 2.12787 7.42022 3.69604C5.17136 5.26422 3.39529 7.41875 2.28503 9.92551C1.17476 12.4323 0.77275 15.1954 1.12269 17.9146C1.47263 20.6338 2.56113 23.2051 4.26991 25.3491C5.7757 27.2383 7.7124 28.7321 9.91169 29.7089C10.4038 29.9274 10.9659 29.6658 11.152 29.1605V29.1605C11.3382 28.6552 11.0776 28.098 10.5876 27.8747C8.72191 27.0243 7.07841 25.7442 5.79482 24.1337C4.30818 22.2684 3.36119 20.0314 3.05674 17.6657C2.75229 15.3 3.10204 12.8961 4.06797 10.7152C5.03391 8.53431 6.57908 6.65987 8.53559 5.29556C10.4921 3.93125 12.7851 3.12925 15.1654 2.97671C17.5458 2.82418 19.9224 3.32694 22.0371 4.43036C24.1517 5.53379 25.9235 7.19567 27.1599 9.23542C28.2274 10.9966 28.8606 12.9813 29.0136 15.0259C29.0538 15.5628 29.4865 16 30.025 16V16Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
);
