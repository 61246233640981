export const Logo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M52.5395 9.02403H49.544C49.7366 7.52041 50.1807 7.37945 51.7172 7.37945H52.7415L53.0681 4.51553H51.7501C48.1978 4.51553 46.5696 5.57746 46.1843 9.02638L44.8381 20.5243H48.1531L49.1587 11.8997H52.2129L52.5395 9.02403Z"></path>
    <path d="M40.2498 9.01227H38.0226C35.0482 9.01227 33.3238 10.0812 32.8374 13.8215L32.0715 20.522H35.3137L36.0796 14.1927C36.2699 12.5622 36.6787 12.0782 38.2152 12.0782H39.8762L40.2498 9.01227Z"></path>
    <path d="M53.8716 9.01227H57.1232L55.784 20.5243H52.5348L53.8716 9.01227Z"></path>
    <path d="M41.5866 9.01227H44.8381L43.499 20.5243H40.2498L41.5866 9.01227Z"></path>
    <path d="M54.3931 4.50613H57.6423L57.3087 7.38414H54.0571L54.3931 4.50613Z"></path>
    <path d="M42.1082 4.50613H45.3574L45.0238 7.38414H41.7722L42.1082 4.50613Z"></path>
    <path d="M66.4621 18.2266H62.7876C60.7883 18.2266 60.0835 17.16 60.2597 15.7151H63.1353C65.8724 15.7151 67.4559 14.3384 67.5992 12.3179C67.7213 10.0672 65.6821 9.04282 63.5277 9.04282C60.4876 9.04282 58.2063 10.9999 57.8656 14.0118C57.7904 14.6884 57.767 14.8129 57.6706 15.7151C57.4357 17.8507 58.5892 20.522 62.0734 20.522H66.1895L66.4621 18.2266ZM63.093 11.2724C64.3711 11.2724 65.0759 11.606 64.8833 12.5364C64.7705 13.22 64.1056 13.5114 62.8651 13.5114H60.4852C60.5592 12.8788 60.8685 12.2973 61.3517 11.8824C61.8349 11.4676 62.4566 11.2498 63.093 11.2724Z"></path>
    <path d="M76.2919 4.50613L75.7045 9.40697C75.1725 9.15255 74.5909 9.01858 74.0012 9.01463C70.9611 9.01463 69.1121 10.8941 68.7691 13.9155C68.6939 14.5921 68.6704 14.7166 68.5741 15.6188C68.3391 17.7544 69.4246 20.4938 72.9087 20.4938H77.6334L79.5364 4.51788L76.2919 4.50613ZM75.284 14.1105C75.0678 15.8373 74.8399 17.8695 74.8399 17.8695H73.2776C71.492 17.8695 71.1631 16.6384 71.2947 15.56L71.4427 14.2679C71.6776 12.5176 72.5892 11.3805 74.1422 11.6201C75.338 11.8151 75.4555 12.7079 75.2793 14.1105H75.284Z"></path>
    <path d="M2.77934 6.78976H6.76861V2.80988H15.7574V12.0782L18.5367 5.8265V1.39319V0H17.4255H16.2202H4.4897L0 4.4944V22.8667V24.0743V25.0023H15.3322L16.6431 22.2182H2.77934V6.78976Z"></path>
    <path d="M23.0546 0L15.7292 16.1662L12.7643 9.57379H9.33179L14.1668 20.522H17.4137L26.5505 0H23.0546Z"></path>
    <path d="M30.7795 18.2266H27.105C25.1057 18.2266 24.4009 17.16 24.5771 15.7151H27.4504C30.1874 15.7151 31.7733 14.3384 31.9142 12.3179C32.0364 10.0672 29.9971 9.04282 27.8427 9.04282C24.8026 9.04282 22.5237 10.9999 22.1807 14.0118C22.1055 14.6884 22.0844 14.8129 21.9857 15.7151C21.7507 17.8507 22.9043 20.522 26.3885 20.522H30.4905L30.7795 18.2266ZM27.4104 11.2724C28.6909 11.2724 29.3933 11.606 29.2007 12.5364C29.0879 13.22 28.423 13.5114 27.1849 13.5114H24.8026C24.8766 12.8788 25.1859 12.2973 25.6691 11.8824C26.1523 11.4676 26.774 11.2498 27.4104 11.2724Z"></path>
  </svg>
);
