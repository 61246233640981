import React from "react";
import { TextVariants } from "../../types/TextVariants";
import styles from "./styles.module.css";

type Props = {
  variant: TextVariants;
  label: string;
};

const TextBox = ({ variant, label }: Props) => {
  return (
    <p
      className={
        variant === TextVariants.WARNING ? styles.warning : styles.info
      }
    >
      {label}
    </p>
  );
};

export default TextBox;
