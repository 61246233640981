import { language } from "ve-ui-theme/src/icons";
import { Page } from "../../../../../../types";
import { LanguageMap } from "../LanguageMap";

export const mapChosenLanguage: producer = async ({
  chosenLanguage = observe.pages[Page.APP_CLIENT].formData.language,
  mappedChosenLanguage = update.pages[Page.APP_CLIENT].formData
    .mappedChosenLanguage,
}) => {
  const label =
    LanguageMap[chosenLanguage as keyof typeof LanguageMap] || chosenLanguage;
  mappedChosenLanguage.set({ value: chosenLanguage, label });
};
