import React from "react";
import styles from "./style.module.css";

type Props = {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};

export const FormCard = ({ children }: Props) => {
  return <div className={styles.formCardContainer}>{children}</div>;
};
