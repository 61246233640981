import { Button } from "@c11/components";
import React from "react";
import { OrganizationAppClient } from "../../types/OrganizationAppClient";
import { Container } from "../../components/container/Container";
import { CreateAppClientModal } from "../OrganizationClientManagement/CreateAppClientModal";
import * as Producers from "./producers";
import style from "./styles.module.css";
import {
  AsyncRequestState,
  EngineUpdateType,
  Page,
  PageStates,
} from "../../types";
import { Loader } from "../../components/Loader/Loader";
import { ConfirmDeleteModal } from "../../components/confirmDeleteModal/ConfirmDeleteModal";
import Card from "../../components/Card/Card";
import { Case, DefaultCase, Switch } from "@c11/components.conditionals";
import { Divider } from "../../components/divider/Divider";

interface EngineProps {
  updateOrgTrigger: EngineUpdateType;
  modalIsOpen: boolean;
  modalTrigger: EngineUpdateType;
  items: OrganizationAppClient[];
  updateShowDeleteModal: EngineUpdateType;
  showDeleteModal: boolean;
  updateCurrentAppClientId: EngineUpdateType;
  currentClientId: string;
  updateDeleteAppClientTrigger: EngineUpdateType;
  deleteAppClientRequest: string;
  updateCurrentAppClientName: EngineUpdateType;
  currentAppClientName: string;
  pageState: string;
}

const AppClientsList: view = ({
  items = observe.organization.items,
  updateCurrentAppClientId = update.clients.currentClientId,
  updateCurrentPage = update.pages.currentPage,
  updateCurrentAppClientName = update.organization.currentClientName,
  updateAppClientName = update.clients.appClientName,
  updateShowDeleteModal = update.organization.showDeleteModal,
  modalTrigger = update.organization.modalIsOpen,
  organizationItems = observe.organization.items,
  t = observe.lang.t,
}) => {
  const handleOpenConfirmDeleteModal = (
    clientID: string,
    clientName: string
  ) => {
    updateCurrentAppClientId.set(clientID);
    updateCurrentAppClientName.set(clientName);
    updateShowDeleteModal.set(true);
  };

  const handleOpenModal = () => {
    updateAppClientName.set("");
    modalTrigger.set(true);
  };
  return (
    <>
      {organizationItems ? <h2>App Clients</h2> : <h2>No App Clients yet</h2>}
      <Divider size="md" />
      {items.map((item: OrganizationAppClient, i: number) => (
        <Card key={item.clientID}>
          <div className={style.appContainerInfo}>
            <div>
              <h3>{`App Client name: ${item.name}`}</h3>
              <div>{`Client ID: ${item.clientID}`}</div>
            </div>
          </div>
          <Button
            onClick={() => {
              updateCurrentAppClientId.set(item.clientID);
              updateCurrentPage.set(Page.APP_CLIENT);
            }}
            variant="secondary"
            className={style.editButton}
          >
            Edit
          </Button>
          <Button
            variant="danger"
            className={style.editButton}
            onClick={() =>
              handleOpenConfirmDeleteModal(item.clientID, item.name)
            }
          >
            Delete
          </Button>
        </Card>
      ))}
      <div className={style.dashboardContainer}>
        <div className={style.buttonContainer}>
          <Button className={style.primaryButton} onClick={handleOpenModal}>
            Create new
          </Button>
        </div>
      </div>
    </>
  );
};

const EmptyAppClientsCard: view = ({
  modalTrigger = update.organization.modalIsOpen,
}) => {
  return (
    <Card className={`${style.emptyCardContainer}`}>
      <div className={style.appContainerInfo}>
        <h3>No app clients found</h3>
        <p>It's time to create your first app client</p>
      </div>
      <Button onClick={() => modalTrigger.set(true)}>Create new</Button>
    </Card>
  );
};

const DasboardLoadedState: view = ({
  modalIsOpen = observe.organization.modalIsOpen,
  showDeleteModal = observe.organization.showDeleteModal,
  updateShowDeleteModal = update.organization.showDeleteModal,
  currentAppClientName = observe.organization.currentClientName,
  updateDeleteAppClientTrigger = update.organization.triggers
    .deleteAppClientTrigger,
  deleteAppClientRequest = observe.clients.requests.deleteAppClient,
  appClientsCounter = observe.organization.items.length,
}) => {
  const handleOnDelete = () => {
    updateDeleteAppClientTrigger.set(true);
  };

  const handleOnCancelDelete = () => {
    updateShowDeleteModal.set(false);
  };
  return (
    <>
      {modalIsOpen && <CreateAppClientModal />}
      {showDeleteModal && (
        <ConfirmDeleteModal
          isLoading={deleteAppClientRequest === AsyncRequestState.PENDING}
          onCancel={handleOnCancelDelete}
          onConfirm={handleOnDelete}
          appName={currentAppClientName}
          isCompleted={deleteAppClientRequest === AsyncRequestState.FULFILLED}
        />
      )}
      {appClientsCounter > 0 ? <AppClientsList /> : <EmptyAppClientsCard />}
    </>
  );
};

const DashboardErrorState: view = ({
  updateOrgTrigger = update.organization.triggers.fetchOrganizations,
}) => {
  return (
    <div>
      <h2>
        We ecountered an error while trying to fetch your settings. If this
        error persists, contact our support.
      </h2>
      <Button onClick={() => updateOrgTrigger.set(Date.now())}>
        Try again
      </Button>
    </div>
  );
};

export const Dashboard: view = ({
  pageState = observe.pages[Page.DASHBOARD].pageState,
}: EngineProps) => {
  return (
    <Container>
      <Switch value={pageState}>
        <Case is={PageStates.LOADING} element={<Loader />} />
        <Case is={PageStates.INITIALISED} element={<DasboardLoadedState />} />
        <Case is={PageStates.FAILED} element={<DashboardErrorState />} />
        <DefaultCase element={<Loader />} />
      </Switch>
    </Container>
  );
};

Dashboard.producers(Producers);
