import React from "react";

export const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="23"
      viewBox="0 0 13 23"
      fill="none"
    >
      <path
        d="M1.60645 21.6511L11.6064 11.6511L1.60645 1.65112"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
