export const RoundedFlags = () => (
  <svg
    width="240"
    height="24"
    viewBox="0 0 240 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1601_922)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#EEEEEE"
      />
      <path
        d="M9.38909 10.4344H23.8969C23.517 7.54796 22.1008 4.89804 19.9121 2.97835C17.7233 1.05867 14.9113 0.000157227 12 2.28047e-05C11.122 -0.00168583 10.2465 0.0926248 9.38909 0.281273V10.4344ZM6.26252 10.4344V1.45783C4.60138 2.36318 3.17763 3.64817 2.10726 5.2081C1.0369 6.76802 0.350212 8.55876 0.103149 10.4344H6.26252ZM6.26252 13.5656H0.103149C0.350212 15.4413 1.0369 17.232 2.10726 18.7919C3.17763 20.3519 4.60138 21.6368 6.26252 22.5422V13.5656ZM9.38909 13.5656V23.7141C10.2463 23.9043 11.1219 24.0002 12 24C14.9113 23.9999 17.7233 22.9413 19.9121 21.0217C22.1008 19.102 23.517 16.4521 23.8969 13.5656H9.38909Z"
        fill="#D80027"
      />
      <path
        d="M24.75 16.1718C25.6024 18.4683 27.1373 20.4489 29.1484 21.8476C31.1594 23.2463 33.5504 23.996 36 23.996C38.4496 23.996 40.8406 23.2463 42.8516 21.8476C44.8627 20.4489 46.3976 18.4683 47.25 16.1718L36 15.1311L24.75 16.1718Z"
        fill="#FFDA44"
      />
      <path
        d="M36 0C33.5499 0.000299071 31.1586 0.750567 29.1475 2.14997C27.1364 3.54938 25.6018 5.53086 24.75 7.82812L36 8.86875L47.25 7.82812C46.3982 5.53086 44.8636 3.54938 42.8525 2.14997C40.8414 0.750567 38.4501 0.000299071 36 0Z"
        fill="#333333"
      />
      <path
        d="M24.75 7.82812C23.7499 10.5195 23.7499 13.4805 24.75 16.1719H47.25C48.2506 13.4806 48.2506 10.5194 47.25 7.82812H24.75Z"
        fill="#D80027"
      />
      <path
        d="M60 24C66.6274 24 72 18.6274 72 12C72 5.37258 66.6274 0 60 0C53.3726 0 48 5.37258 48 12C48 18.6274 53.3726 24 60 24Z"
        fill="#EEEEEE"
      />
      <path
        d="M50.4844 4.69228C49.5263 5.93498 48.8223 7.35416 48.4125 8.86884H54.6563L50.4844 4.69697V4.69228ZM71.5875 8.86884C71.1786 7.35616 70.4762 5.93865 69.5203 4.69697L65.3484 8.86884H71.5828H71.5875ZM48.4125 15.1314C48.8215 16.644 49.5238 18.0616 50.4797 19.3032L54.6516 15.1314H48.4219H48.4125ZM67.3031 2.47978C66.0611 1.52559 64.6436 0.824816 63.1313 0.417285V6.65634L67.3031 2.48447V2.47978ZM52.6969 21.5204C53.9403 22.4752 55.3595 23.176 56.8734 23.5829V17.3439L52.7016 21.5157L52.6969 21.5204ZM56.8688 0.412598C55.3561 0.821545 53.9386 1.52393 52.6969 2.47978L56.8688 6.65634V0.412598ZM63.1313 23.5876C64.6439 23.1787 66.0614 22.4763 67.3031 21.5204L63.1313 17.3486V23.5829V23.5876ZM65.3438 15.1314L69.5156 19.3032C70.4731 18.0619 71.1771 16.6444 71.5875 15.1314H65.3438Z"
        fill="#0052B4"
      />
      <path
        d="M71.8969 10.4344H61.5656V0.103125C61.0465 0.0349757 60.5236 0.000528554 60 0C59.4703 0 58.9453 0.0328125 58.4344 0.103125V10.4344H48.1031C48.035 10.9535 48.0005 11.4764 48 12C48 12.5297 48.0328 13.0547 48.1031 13.5656H58.4344V23.8969C59.4737 24.0324 60.5263 24.0324 61.5656 23.8969V13.5656H71.8969C71.965 13.0465 71.9995 12.5236 72 12C72 11.4703 71.9672 10.9453 71.8969 10.4344Z"
        fill="#D80027"
      />
      <path
        d="M63.1312 15.1312L68.4844 20.4844C68.7297 20.2384 68.9643 19.9819 69.1875 19.7156L64.6078 15.1312H63.1312ZM56.8687 15.1312L51.5156 20.4844C51.7616 20.7297 52.0181 20.9643 52.2844 21.1875L56.8687 16.6078V15.1312ZM56.8687 8.86875L51.5156 3.51562C51.2703 3.7616 51.0357 4.01809 50.8125 4.28438L55.3922 8.86875H56.8687ZM63.1312 8.86875L68.4844 3.51562C68.2384 3.27024 67.9819 3.03565 67.7156 2.8125L63.1312 7.39219V8.86875Z"
        fill="#D80027"
      />
      <path
        d="M84 24C90.6274 24 96 18.6274 96 12C96 5.37258 90.6274 0 84 0C77.3726 0 72 5.37258 72 12C72 18.6274 77.3726 24 84 24Z"
        fill="#D80027"
      />
      <path
        d="M73.6078 6C72.5546 7.82422 72 9.89355 72 12C72 14.1065 72.5546 16.1758 73.6078 18H94.3922C95.4454 16.1758 96 14.1065 96 12C96 9.89355 95.4454 7.82422 94.3922 6H73.6078Z"
        fill="#FFDA44"
      />
      <path
        d="M108 24C114.627 24 120 18.6274 120 12C120 5.37258 114.627 0 108 0C101.373 0 96 5.37258 96 12C96 18.6274 101.373 24 108 24Z"
        fill="#EEEEEE"
      />
      <path
        d="M119.897 10.4344H105.389V0.285889C104.297 0.527895 103.245 0.922389 102.262 1.45776V10.4344H96.1031C95.9675 11.4737 95.9675 12.5263 96.1031 13.5656H102.262V22.5422C103.245 23.0776 104.297 23.4721 105.389 23.7141V13.5656H119.897C120.032 12.5263 120.032 11.4737 119.897 10.4344Z"
        fill="#0052B4"
      />
      <path
        d="M228 24C234.627 24 240 18.6274 240 12C240 5.37258 234.627 0 228 0C221.373 0 216 5.37258 216 12C216 18.6274 221.373 24 228 24Z"
        fill="#f4ca15"
      />
      <path
        d="M240 12C240 9.54991 239.249 7.15861 237.85 5.14749C236.451 3.13636 234.469 1.60179 232.172 0.75V23.25C234.469 22.3982 236.451 20.8636 237.85 18.8525C239.249 16.8414 240 14.4501 240 12Z"
        fill="#0056D8"
      />
      <path
        d="M216 12C216 14.4501 216.751 16.8414 218.15 18.8525C219.549 20.8636 221.531 22.3982 223.828 23.25V0.75C221.531 1.60179 219.549 3.13636 218.15 5.14749C216.751 7.15861 216 9.54991 216 12Z"
        fill="#B40000"
      />
      <path
        d="M132 24C138.627 24 144 18.6274 144 12C144 5.37258 138.627 0 132 0C125.373 0 120 5.37258 120 12C120 18.6274 125.373 24 132 24Z"
        fill="#EEEEEE"
      />
      <path
        d="M144 12C144 9.54991 143.249 7.15861 141.85 5.14749C140.451 3.13636 138.469 1.60179 136.172 0.75V23.25C138.469 22.3982 140.451 20.8636 141.85 18.8525C143.249 16.8414 144 14.4501 144 12Z"
        fill="#D80027"
      />
      <path
        d="M120 12C120 14.4501 120.751 16.8414 122.15 18.8525C123.549 20.8636 125.531 22.3982 127.828 23.25V0.75C125.531 1.60179 123.549 3.13636 122.15 5.14749C120.751 7.15861 120 9.54991 120 12Z"
        fill="#0052B4"
      />
      <path
        d="M156 24C162.627 24 168 18.6274 168 12C168 5.37258 162.627 0 156 0C149.373 0 144 5.37258 144 12C144 18.6274 149.373 24 156 24Z"
        fill="#EEEEEE"
      />
      <path
        d="M144.412 15.1313C145.102 17.6714 146.608 19.9145 148.697 21.5156V15.1313H144.412ZM154.959 23.9531C155.305 23.9836 155.653 23.9993 156 24C158.638 24.0007 161.202 23.1324 163.297 21.5292C165.391 19.9261 166.899 17.6775 167.587 15.1313H154.955V23.9531H154.959ZM167.587 8.8688C166.852 6.14941 165.184 3.77538 162.874 2.1625C160.564 0.549621 157.761 -0.199295 154.955 0.0469152V8.8688H167.587ZM148.697 2.48442C146.608 4.08553 145.102 6.32866 144.412 8.8688H148.697V2.48442Z"
        fill="#D80027"
      />
      <path
        d="M167.897 10.4344H153.389V0.285889C152.297 0.527895 151.245 0.922389 150.263 1.45776V10.4344H144.103C143.968 11.4737 143.968 12.5263 144.103 13.5656H150.263V22.5422C151.245 23.0776 152.297 23.4721 153.389 23.7141V13.5656H167.897C168.032 12.5263 168.032 11.4737 167.897 10.4344Z"
        fill="#0052B4"
      />
      <path
        d="M180 24C186.627 24 192 18.6274 192 12C192 5.37258 186.627 0 180 0C173.373 0 168 5.37258 168 12C168 18.6274 173.373 24 180 24Z"
        fill="#EEEEEE"
      />
      <path
        d="M180 0C177.55 0.000299071 175.159 0.750567 173.147 2.14997C171.136 3.54938 169.602 5.53086 168.75 7.82812H191.25C190.398 5.53086 188.864 3.54938 186.853 2.14997C184.841 0.750567 182.45 0.000299071 180 0Z"
        fill="#A2001D"
      />
      <path
        d="M180 24C182.45 23.9997 184.841 23.2494 186.853 21.85C188.864 20.4506 190.398 18.4691 191.25 16.1719H168.75C169.602 18.4691 171.136 20.4506 173.147 21.85C175.159 23.2494 177.55 23.9997 180 24Z"
        fill="#0052B4"
      />
      <path
        d="M204 24C210.627 24 216 18.6274 216 12C216 5.37258 210.627 0 204 0C197.373 0 192 5.37258 192 12C192 18.6274 197.373 24 204 24Z"
        fill="#FFDA44"
      />
      <path
        d="M201.389 10.4344H215.897C215.517 7.54796 214.101 4.89804 211.912 2.97835C209.723 1.05867 206.911 0.000157227 204 2.28047e-05C203.122 -0.00168583 202.247 0.0926248 201.389 0.281273V10.4344ZM198.262 10.4344V1.45783C196.601 2.36318 195.178 3.64817 194.107 5.2081C193.037 6.76802 192.35 8.55876 192.103 10.4344H198.262ZM198.262 13.5656H192.103C192.35 15.4413 193.037 17.232 194.107 18.7919C195.178 20.3519 196.601 21.6368 198.262 22.5422V13.5656ZM201.389 13.5656V23.7141C202.246 23.9043 203.122 24.0002 204 24C206.911 23.9999 209.723 22.9413 211.912 21.0217C214.101 19.102 215.517 16.4521 215.897 13.5656H201.389Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1601_922">
        <rect width="240" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
