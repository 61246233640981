import { language } from "ve-ui-theme/src/icons";
import { Page } from "../../../../../../types";
import { LanguageMap } from "../LanguageMap";

export const mapAvailableLanguages: producer = async ({
  availableLanguages = observe.pages[Page.APP_CLIENT].availableLanguages,
  mappedAvailableLanguages = update.pages[Page.APP_CLIENT]
    .mappedAvailableLanguages,
}) => {
  mappedAvailableLanguages.set(
    availableLanguages.map((language: string) => ({
      value: language,
      label: LanguageMap[language as keyof typeof LanguageMap] || "language",
    }))
  );
};
